import { css } from 'glamor'
import _ from 'lodash'
import { toastr } from 'react-redux-toastr'
import branditRails, { branditRailsSkipInterceptors } from '../api/brandit_rails'
import history from '../history'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import { store } from '../redux/storeConfig/store'
import { isProductNeedsConfiguration, mapQtyToColor, saveUrlToLocalStorage } from '../utility/Utils'
import { change } from 'redux-form'
import { NODE_ENV, REACT_APP_BACKEND_API_URL } from '../Constants'
import { redirectToLogin } from './agencies'
import * as Types from './types'

branditRails.interceptors.response.use(
  response => {
    // Check API version and app version for new deployments
    const currentApiVersion = response.headers['x-api-version']
    const storedApiVersion = localStorage.getItem('apiVersion')

    if (currentApiVersion && (!storedApiVersion || currentApiVersion !== storedApiVersion)) {
      localStorage.setItem('apiVersion', currentApiVersion)

      // toastr.info('A new version of the app is available. Please log in again to access your account.', {
      //   toastId: 'newVersionAvailable',
      // timeOut: 5000,
      // })
      // setTimeout(() => {
      //   store.dispatch(handleLogout())
      // }, 4000)
    }
    return response
  },
  error => {
    if (error.code === 'ERR_NETWORK') {
      toastr.error('Network Error', error.message, {
        className: css({ background: 'red !important' })
      })
      return
    }
    switch (error.response?.status) {
      case 400:
        toastr.error('Error: 400', 'Bad Request', {
          className: css({ background: 'red !important' })
        })
        history.back()
        //pop up and go back
        break
      case 401:
        if (!error.response?.data) {
          toastr.error('Error', 'You must be signed in', {
            className: css({ background: 'red !important' })
          })
        } else if (error.response.data?.error_code) {
          if (error.response.data.error_code === 'not_active') {
            toastr.error('Error', 'Your account is not active', {
              className: css({ background: 'red !important' })
            })
          } else if (error.response.data.error_code === 'token_required') {
            toastr.error('Error', 'Token is required to sign in', {
              className: css({ background: 'red !important' })
            })
          } else if (error.response.data.error_code === 'auth0_login_failed') {
            toastr.error('Error', error.response.data.message, {
              className: css({ background: 'red !important' })
            })
          } else {
            toastr.error('Error', 'You must be signed in', {
              className: css({ background: 'red !important' })
            })
          }
          // redirect to log in
          // Redirect should not happen for session-related routes
        } else if (error.response.data?.errors.length) {
          toastr.error('Error', error.response.data.errors[0].message, {
            className: css({ background: 'red !important' })
          })
        }
        // known issue: the below refreshes the page because of incorrect use of history,
        // so any error messages displayed above will not be there long.
        redirectToLogin(error)
        break
      case 403:
        toastr.error('Error: 403', `Forbidden: ${error.response.data.errors[0].message}`, {
          className: css({ background: 'red !important' })
        })
        history.back()
        //go back and give pop up
        break
      case 422:
        //give pop up
        if (error.response.data && error.response.data.error_message) {
          toastr.error('Error', error.response.data.error_message, {
            className: css({ background: 'red !important' }),
            timeOut: 8000
          })
        } else {
          toastr.error('Error', 'Unable to process request', {
            className: css({ background: 'red !important' })
          })
        }
        break
      case 500:
        toastr.error('Error: 500', 'Internal Server Error', {
          className: css({ background: 'red !important' })
        })
        break
      case 502:
        toastr.error('Error: 502', 'Bad Gateway', {
          className: css({ background: 'red !important' })
        })
        break
      default:
        if (NODE_ENV === 'production') {
          // Bugsnag.notify(error)
        }
        break
    }
    return Promise.reject(error)
  }
)

// THEME
export const setTheme = item => dispatch => {
  dispatch({ type: 'THEME_MODE', payload: item })
}

// QueryParams

const setParams = queryParams => {
  const params = `?skip=${queryParams.skip}&limit=${queryParams.limit}&sortInfo=${JSON.stringify(
    queryParams.sortInfo
  )}&filterValue=${JSON.stringify(queryParams.filterValue)}&client_id=${queryParams.client_id}`
  return params
}

// ------ CLIENTS -------

export const fetchClientsGrid = queryParams => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  const params = setParams(queryParams)

  return await branditRails.get(`/clients/index_grid${params}`).then(function (response) {
    const totalCount = response.headers['x-total-count']
    return Promise.resolve({
      data: response.data,
      count: parseInt(totalCount)
    })
    // dispatch({ type: Types.FETCH_ADMINS, payload: response.data });
  })
}

export const fetchClients = () => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails.get('/clients').then(function (response) {
    dispatch({ type: Types.FETCH_CLIENTS, payload: response.data })
  })
}

export const fetchClient = id => async dispatch => {
  await branditRails.get(`/clients/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_CLIENT, payload: response.data })
  })
}

export const fetchClientsCCDataPoints = id => async dispatch => {
  await branditRails.get(`/client/cc_data_points/${id}`).then(function (response) {
    dispatch({
      type: Types.FETCH_CLIENT_CC_DATA_POINT,
      payload: response.data
    })
  })
}

export const fetchClientsCCDataPointsGrid = async queryParams => {
  const params = setParams(queryParamois)
  return await branditRails.get(`/client/cc_data_points/index_grid/${params}`).then(function (response) {
    const totalCount = response.headers['x-total-count']

    return Promise.resolve({
      data: response.data,
      count: parseInt(totalCount)
    })
  })
}

export const editCCDataPoint = async (id, columnId, value) => {
  // return await branditRails.post(`/client/cc_data_point/` + id + `/` + alias)
  return await branditRails.post(`/client/cc_data_point/${id}/${columnId}/${value}`).then(function (response) {
    // const totalCount = response.headers['x-total-count'];
    if (response.status == 200) {
      toastr.success('Update', 'Record updated successfully!', {
        className: css({ background: 'green !important' })
      })
    } else {
      toastr.error('Update', 'An error has occurred when trying to update the record. Please contact the tech team.', {
        className: css({ background: 'red !important' })
      })
    }
    return Promise.resolve({
      data: response.data // count: parseInt(totalCount)
    })
  })
}
export const createCCDataPoint = async (client_id, alias, dataType) => {
  return await branditRails
    .post(`/client/cc_data_point/store/${client_id}/${alias}/${dataType}`)
    .then(function (response) {
      // const totalCount = response.headers['x-total-count'];
      if (response.status == 200) {
        toastr.success('Alias Update', 'Alias updated successfully!', {
          className: css({ background: 'green !important' })
        })
      } else {
        toastr.error(
          'Alias Update',
          'An error has occurred when trying to update the alias. Please contact the tech team.',
          {
            className: css({ background: 'red !important' })
          }
        )
      }
      return Promise.resolve({
        data: response.data // count: parseInt(totalCount)
      })
    })
}
export const fetchCCDataPoints = client_id => async dispatch => {
  await branditRails.get(`/clients/${client_id}/cc_data_points`).then(function (response) {
    dispatch({ type: 'FETCH_CC_DATA_POINTS', payload: response.data })
  })
}

export const createClient = formValues => async (dispatch, getState) => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post('/clients', formValues)
    .then(function (response) {
      dispatch({ type: Types.CREATE_CLIENT, payload: response.data })
      toastr.success('Create Client', 'Client created successfully!', {
        className: css({ background: 'green !important' })
      })
    })
    .catch(function (error) {
      if (error.response && error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const editClient = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .patch(`/clients/${id}`, formValues)
    .then(function (response) {
      dispatch({ type: Types.EDIT_CLIENT, payload: response.data })
      toastr.success('Update Client', 'Client updated successfully!')
      history.back()
    })
    .catch(function (error) {
      if (error.response && error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const deleteClient = id => async dispatch => {
  await branditRails.delete(`/clients/${id}`).then(function (response) {
    dispatch({ type: Types.DELETE_CLIENT, payload: id })
    history.back()
  })
}

export const deleteClientLogo = (model, attribute, id) => async dispatch => {
  await branditRails.delete(`/delete_client_logo/${model}/${attribute}/${id}`).then(function (response) {
    dispatch({ type: Types.DELETE_CLIENT_LOGO, payload: response.data })
  })
  toastr.success('Client logo', 'Client logo deleted successfully!')
}

export const deleteImage = (model, attribute, id) => async dispatch => {
  await branditRails.delete(`/delete_image/${model}/${attribute}/${id}`).then(function (response) {
    dispatch({ type: Types.DELETE_IMAGE, payload: response.data })
  })

  toastr.success('Image Delete', 'Image deleted successfully!')
}

// ------ SETTINGS -------
export const fetchSettings = () => async dispatch => {
  await branditRails.get('/system_settings').then(function (response) {
    dispatch({ type: Types.FETCH_SETTINGS, payload: response.data })
  })
}

export const fetchSetting = id => async dispatch => {
  await branditRails.get(`/system_settings/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_SETTING, payload: response.data })
  })
}

export const editSetting = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .patch(`/system_settings/${id}`, formValues)
    .then(function (response) {
      dispatch({ type: Types.EDIT_SETTING, payload: response.data })
      toastr.success('Update Setting', 'Setting updated successfully!')
      history.back()
    })
    .catch(function (error) {
      if (error.response && error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

// ------ PORTALS -------
export const fetchPortals =
  (options = null) =>
  async dispatch => {
    dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
    const url = `/portals${options ? optionsToParams(options) : ''}`
    await branditRails.get(url).then(function (response) {
      dispatch({ type: Types.FETCH_PORTALS, payload: response.data })
    })
  }

export const fetchPortalsGrid = queryParams => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  const params = setParams(queryParams)
  return await branditRails.get(`/portals/index_grid/${params}`).then(function (response) {
    const totalCount = response.headers['x-total-count']
    return Promise.resolve({
      data: response.data,
      count: parseInt(totalCount)
    })
    // dispatch({ type: Types.FETCH_ADMINS, payload: response.data });
  })
}

export const fetchProductsGrid = queryParams => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  const params = setParams(queryParams)
  return await branditRails.get(`/products/index_grid/${params}`).then(function (response) {
    const totalCount = response.headers['x-total-count']
    return Promise.resolve({
      data: response.data,
      count: parseInt(totalCount)
    })
    // dispatch({ type: Types.FETCH_ADMINS, payload: response.data });
  })
}

// export const fetchPortals = () => async dispatch => {
//   dispatch({ type: Types.CLEAR_ERRORS, payload: '' });
//   await branditRails.get('/portals')
//   .then(function (response) {
//     dispatch({ type: Types.FETCH_PORTALS, payload: response.data });
//   });

// };

export const fetchClientPortals = client_id => async dispatch => {
  await branditRails.get(`/clients/${client_id}/portals`).then(function (response) {
    dispatch({ type: 'FETCH_CLIENT_PORTALS', payload: response.data })
  })
}

export const fetchPortal = id => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails.get(`/portals/${id}`).then(function (response) {
    const portal = response.data
    dispatch({ type: Types.FETCH_PORTAL, payload: portal })
  })
}

export const createPortal = formValues => async (dispatch, getState) => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post('/portals', formValues)
    .then(function (response) {
      dispatch({ type: Types.CREATE_PORTAL, payload: response.data })
      toastr.success('Create Portal', 'Portal created successfully!')
      history.back()
    })
    .catch(function (error) {
      if (error.response && error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const editPortal = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .patch(`/portals/${id}`, formValues)
    .then(function (response) {
      dispatch({ type: Types.EDIT_PORTAL, payload: response.data })
      toastr.success('Update Portal', 'Portal updated successfully!')
      history.back()
    })
    .catch(function (error) {
      if (error.response && error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const deletePortal = id => async dispatch => {
  await branditRails.delete(`/portals/${id}`).then(function (response) {
    dispatch({ type: Types.DELETE_PORTAL, payload: id })
    history.back()
  })
}

export const fetchPortalTraditionalDeliveryAddresses = portal_id => async dispatch => {
  await branditRails.get(`/portals/${portal_id}/traditional_delivery_addresses`).then(function (response) {
    dispatch({
      type: Types.FETCH_PORTAL_TRADITIONAL_DELIVERY_ADDRESSES,
      payload: response.data
    })
  })
}

// ------ PAGES -------
export const fetchPage = id => async dispatch => {
  return await branditRails.get(`/pages/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_PAGE, payload: response.data })
    return response.data
  })
}

export const fetchPortalPages = portal_id => async dispatch => {
  await branditRails.get(`/portals/${portal_id}/pages`).then(function (response) {
    return new Promise((resolve, reject) => {
      dispatch({ type: Types.FETCH_PORTAL_PAGES, payload: response.data })
      resolve()
    })
  })
}

export const createPage = formValues => async (dispatch, getState) => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post('/pages', formValues)
    .then(function (response) {
      dispatch({ type: Types.CREATE_PAGE, payload: response.data })
      toastr.success('Create Page', 'Page created successfully!')
      history.back()
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const editPage = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .patch(`/pages/${id}`, formValues)
    .then(function (response) {
      dispatch({ type: Types.EDIT_PAGE, payload: response.data })
      toastr.success('Update Page', 'Page updated successfully!')
      history.back()
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const deletePage = id => async dispatch => {
  await branditRails.delete(`/pages/${id}`).then(function (response) {
    dispatch({ type: Types.DELETE_PAGE, payload: id })
    history.back()
  })
}

export const bulkUpdatePages = pageValues => async dispatch => {
  await branditRails.patch('/pages_bulk_update', pageValues).then(function (response) {
    return new Promise((resolve, reject) => {
      dispatch({ type: Types.BULK_UPDATE_PAGES, payload: response.data })
      resolve()
    })
  })
}

export const setPagesLoading = loading => ({
  type: Types.SET_PAGES_LOADING,
  payload: loading
})

// ------ TEMPLATES -------
export const fetchGenericTemplate = id => async dispatch => {
  await branditRails.get(`/generic_templates/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_GENERIC_TEMPLATE, payload: response.data })
  })
}

export const editGenericTemplate = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .patch(`/generic_templates/${id}`, formValues)
    .then(function (response) {
      dispatch({ type: Types.EDIT_GENERIC_TEMPLATE, payload: response.data })
      toastr.success('Update Content', 'Content updated successfully!')
      history.back()
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const fetchHomeTemplate = id => async dispatch => {
  await branditRails.get(`/home_templates/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_HOME_TEMPLATE, payload: response.data })
  })
}

export const editHomeTemplate = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .patch(`/home_templates/${id}`, formValues)
    .then(function (response) {
      dispatch({ type: Types.EDIT_HOME_TEMPLATE, payload: response.data })
      toastr.success('Update Content', 'Content updated successfully!')
      history.back()
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const fetchAboutTemplate = id => async dispatch => {
  await branditRails.get(`/about_templates/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_ABOUT_TEMPLATE, payload: response.data })
  })
}

export const editAboutTemplate = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .patch(`/about_templates/${id}`, formValues)
    .then(function (response) {
      dispatch({ type: Types.EDIT_ABOUT_TEMPLATE, payload: response.data })
      toastr.success('Update Content', 'Content updated successfully!')
      history.back()
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

// ---- DOMAINS -----

export const fetchDomain = id => async dispatch => {
  await branditRails.get(`/domains/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_DOMAIN, payload: response.data })
  })
}

export const createDomain = formValues => async dispatch => {
  await branditRails.post(`/domains`, formValues).then(function (response) {
    dispatch({ type: Types.CREATE_DOMAIN, payload: response.data })
    toastr.success('Create Domain', 'Domain created successfully!')
    history.back()
  })
}

export const fetchPortalDomains = portal_id => async dispatch => {
  await branditRails.get(`/portals/${portal_id}/domains`).then(function (response) {
    dispatch({ type: Types.FETCH_PORTAL_DOMAINS, payload: response.data })
  })
}

export const editDomain = (id, formValues) => async dispatch => {
  await branditRails.patch(`/domains/${id}`, formValues).then(function (response) {
    dispatch({ type: Types.EDIT_DOMAIN, payload: response.data })
    toastr.success('Update Domain', 'Domain updated successfully!')
    history.back()
  })
}

export const deleteDomain = id => async dispatch => {
  await branditRails.delete(`/domains/${id}`).then(function (response) {
    dispatch({ type: Types.DELETE_DOMAIN, payload: id })
    toastr.success('Deleted Domain successfully!')
    history.back()
  })
}

//PERIODS

export const fetchPeriod = id => async dispatch => {
  await branditRails.get(`/periods/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_PERIOD, payload: response.data })
  })
}

export const fetchPortalPeriods = portal_id => async dispatch => {
  await branditRails.get(`/portals/${portal_id}/periods`).then(function (response) {
    dispatch({ type: Types.FETCH_PORTAL_PERIODS, payload: response.data })
  })
}

export const fetchPortalAccounts = (portal_id, page, formValues) => async dispatch => {
  await branditRails.post(`/portals/${portal_id}/accounts/${page}`, formValues).then(function (response) {
    dispatch({ type: Types.FETCH_PORTAL_ACCOUNTS, payload: response.data })
  })
}

export const sendBalanceReminders = (portal_id, admin_id, formValues) => async dispatch => {
  await branditRails.post(`/portals/${portal_id}/admin/${admin_id}`, formValues).then(function (response) {
    dispatch({
      type: Types.SEND_BALANCE_REMINDERS,
      payload: response.data
    })
    toastr.success('Balance Reminders', 'Balance reminders have been sent!')
  })
}

export const getLastRemindersSent = (portal_id, formValues) => async dispatch => {
  await branditRails.post(`/portals/last_reminder_sent/${portal_id}`, formValues).then(function (response) {
    dispatch({
      type: Types.GET_LAST_REMINDER_SENT,
      payload: response.data
    })
  })
}

export const createPeriod = formValues => async dispatch => {
  await branditRails.post(`/periods`, formValues).then(function (response) {
    dispatch({ type: Types.CREATE_PERIOD, payload: response.data })
    toastr.success('Create Period', 'Period created successfully!')
  })
}
export const editPeriod = (id, formValues) => async dispatch => {
  await branditRails.patch(`/periods/${id}`, formValues).then(function (response) {
    dispatch({ type: Types.EDIT_PERIOD, payload: response.data })
    toastr.success('Update Period', 'Period updated successfully!')
  })
}

export const deletePeriod = id => async dispatch => {
  await branditRails.delete(`/periods/${id}`).then(function (response) {
    dispatch({ type: Types.DELETE_PERIOD, payload: id })
  })
}

//SESSIONS

export const deleteSession = () => async dispatch => {
  await branditRails.delete('/sessions').then(function (response) {
    sessionStorage.setItem('admin_token', '')
    sessionStorage.setItem('email', '')
    toastr.success('Signed Out', 'Signed out successfully!')
    history.push('/login')
  })
}

//AUDITS
export const fetchAudits = (id, model) => async dispatch => {
  await branditRails.get(`/audits/${id}/${model}`).then(function (response) {
    dispatch({ type: Types.FETCH_AUDITS, payload: response.data })
  })
}

//ADMINS

export const fetchClientAdmins = client_id => async dispatch => {
  await branditRails.get(`/fetch_client_admins/${client_id}`).then(function (response) {
    dispatch({ type: Types.FETCH_CLIENT_ADMINS, payload: response.data })
  })
}

export const fetchClientAdminsByUser = user_id => async dispatch => {
  await branditRails.get(`/fetch_client_admins_by_user/${user_id}`).then(function (response) {
    dispatch({
      type: Types.FETCH_CLIENT_ADMINS_BY_USER,
      payload: response.data
    })
  })
}

export const fetchAdmin = id => async dispatch => {
  await branditRails.get(`/admins/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_ADMIN, payload: response.data })
  })
}

export const fetchAdminsGrid = queryParams => async dispatch => {
  dispatch({ type: Types.CLEAR_ADMINS, payload: '' })
  const params = setParams(queryParams)

  return await branditRails.get(`/admins/index_grid${params}`).then(function (response) {
    const totalCount = response.headers['x-total-count']
    return Promise.resolve({
      data: response.data,
      count: parseInt(totalCount)
    })
  })
}

export const fetchCreditRedemptionsGrid = queryParams => async dispatch => {
  dispatch({ type: Types.CLEAR_ADMINS, payload: '' })
  const params = setParams(queryParams)

  return await branditRails.get(`/credit_redemptions/index_grid${params}`).then(function (response) {
    const totalCount = response.headers['x-total-count']
    return Promise.resolve({
      data: response.data,
      count: parseInt(totalCount)
    })
    // dispatch({ type: Types.FETCH_ADMINS, payload: response.data });
  })
}

export const fetchFundingTags = portal_id => async dispatch => {
  dispatch({ type: Types.CLEAR_FUNDING_TAGS, payload: '' })
  await branditRails.get(`/funding_tags/${portal_id}`).then(function (response) {
    dispatch({ type: Types.FETCH_FUNDING_TAGS, payload: response.data })
  })
}

export const fetchCoopBalancesByTag = (user_id, location_id, period_id, export_json) => async dispatch => {
  await branditRails
    .get(`/coop_balances_by_tag/${user_id}/${location_id}/${period_id}/${export_json}`)
    .then(function (response) {
      dispatch({
        type: Types.FETCH_COOP_BALANCES_BY_TAG,
        payload: response.data
      })
    })
}

export const createAdmin = formValues => async (dispatch, getState) => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  return await branditRails
    .post('/admins', formValues)
    .then(response => {
      if (response.status === 206) {
        toastr.warning('Create Admin', 'This account cannot be active due to the email not matching the domain list!', {
          className: css({ background: 'red !important' }),
          timeOut: 8000
        })
      }
      if (response?.status === 206 || response?.status === 201) {
        dispatch({ type: Types.CREATE_ADMIN, payload: response.data })
        toastr.success('Create Admin', 'Admin created successfully!')
      } else {
        toastr.error('Create Admin', 'Unable to create admin account. Please try again or contact support', {
          className: css({ background: 'red !important' })
        })
      }

      return response
    })
    .catch(function (error) {
      return error
    })
}

export const editAdmin = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .patch(`/admins/${id}`, formValues)
    .then(function (response) {
      dispatch({ type: Types.EDIT_ADMIN, payload: response.data })

      toastr.success('Update Admin', 'Admin updated successfully!')
      if (response.status === 206) {
        toastr.warning('Update Admin', 'This account cannot be active due to the email not matching the domain list!', {
          className: css({ background: 'red !important' })
        })
      }
      history.back()
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const updatePasswordRequest = () => ({
  type: Types.UPDATE_PASSWORD_REQUEST
})
export const updatePasswordSuccess = data => ({
  type: Types.UPDATE_PASSWORD_SUCCESS,
  payload: data
})

export const updatePassword = (adminId, currentPassword, newPassword) => async dispatch => {
  dispatch(updatePasswordRequest())

  try {
    const response = await branditRails.put(`/admins/${adminId}/update_password`, {
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: newPassword
    })

    if (response.data.error) {
      toastr.error('Update Password Error', response.data.error)
    } else {
      dispatch(updatePasswordSuccess(response.data))
      toastr.success('Success', 'Password updated successfully.')
      history.push('/dashboard')
      history.go(0)
    }
  } catch (error) {
    if (error.response && error.response.status === 422) {
      dispatch({
        type: Types.CREATE_SESSION_ERROR,
        payload: error.response.data
      })
      toastr.error('Update Password Error', error.response.data.error)
    }
  }
}

export const forgotAdminPasswordRequest = () => ({
  type: Types.FORGOT_ADMIN_PASSWORD_REQUEST
})
export const forgotAdminPasswordSuccess = data => ({
  type: Types.FORGOT_ADMIN_PASSWORD_SUCCESS,
  payload: data
})

export const forgotAdminPassword = email => async dispatch => {
  dispatch(forgotAdminPasswordRequest())

  try {
    const response = await branditRails.post('/admins/forgot_password', {
      admin: { email }
    })

    if (response.data.error) {
      toastr.error('Forgot Password Error', response.data.error)
    } else {
      dispatch(forgotAdminPasswordSuccess(response.data))
      toastr.success('Success', 'Password reset instructions sent.')

      await new Promise(resolve => setTimeout(resolve, 2000))

      history.back()
    }
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorMessage = error.response.data.error
      toastr.error('Forgot Password Error', errorMessage)
    }
  }
}

export const verifyPasswordToken = token => async dispatch => {
  try {
    const response = await branditRails.post('/admins/verify_password_token', {
      admin: { token }
    })

    if (response.data.error) {
      dispatch(
        dispatchSweetAlert({
          title: 'Error',
          alertMessage: 'An error occurred with your reset password link. You will be redirected to get a new link.',
          alertType: 'error',
          showCancel: false,
          confirmBtnText: 'Confirm',
          closeOnClickOutside: false,
          onConfirm: () => {
            history.push('/forgot_password')
            history.go(0)
          }
        })
      )
    } else {
      dispatch({
        type: Types.VERIFY_ADMIN_PASSWORD_TOKEN_SUCCESS,
        payload: response.data
      })
    }
  } catch (error) {
    let message = 'An error occurred with your reset password link. You will be redirected to get a new link.'

    if (error.response && error.response.status === 422) {
      message = 'An error occurred with your reset password link. You will be redirected to get a new link.'
    } else if (error.response && error.response.status === 404) {
      message = 'Your reset password link has expired. You will be redirected to get a new link.'
    }

    dispatch(
      dispatchSweetAlert({
        title: 'Error',
        alertMessage: message,
        alertType: 'error',
        showCancel: false,
        confirmBtnText: 'Confirm',
        closeOnClickOutside: false,
        onConfirm: () => {
          history.push('/forgot_password')
          history.go(0)
        }
      })
    )

    dispatch({
      type: Types.VERIFY_ADMIN_PASSWORD_TOKEN_FAILURE,
      payload: error.response ? error.response.data : {}
    })
  }
}

export const resetAdminPasswordRequest = () => ({
  type: Types.RESET_ADMIN_PASSWORD_REQUEST
})
export const resetAdminPasswordSuccess = data => ({
  type: Types.RESET_ADMIN_PASSWORD_SUCCESS,
  payload: data
})

export const resetAdminPassword = (token, newPassword) => async dispatch => {
  dispatch(resetAdminPasswordRequest())

  try {
    const response = await branditRails.put(`/admins/reset_password/${token}`, {
      admin: {
        password: newPassword,
        password_confirmation: newPassword
      }
    })

    if (response.data.error) {
      toastr.error('Reset Password Error', response.data.error)
    } else {
      dispatch(resetAdminPasswordSuccess(response.data))
      toastr.success('Success', 'Password reset successfully.')

      await new Promise(resolve => setTimeout(resolve, 2000))

      saveUrlToLocalStorage()
      history.push('/login')
      history.go(0)
    }
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorMessage = error.response.data.error
      toastr.error('Reset Password Error', errorMessage)
    } else if (error.response && error.response.status === 404) {
      const errorMessage = 'Your token has expired.'
      toastr.error('Reset Password Error', errorMessage)
    }
  }
}

export const fetchCurrentAdmin = () => async dispatch => {
  return await branditRails.get('/admins/current').then(function (response) {
    const adminData = {
      ...response.data,
      id: response.data.id,
      email: response.data.email
    }
    dispatch({ type: 'SET_CURRENT_ADMIN', payload: adminData })
    return response.data
  })
}

export const deleteAdmin = id => async dispatch => {
  await branditRails
    .delete(`/admins/${id}`)
    .then(function (response) {
      dispatch({ type: Types.DELETE_ADMIN, payload: id })
      history.back()
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', 'Cannot delete current admin', {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const createAuthorizedAdminEmailDomain = formValues => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post('/authorized_admin_email_domains', formValues)
    .then(function (response) {
      dispatch({
        type: Types.FETCH_AUTHORIZED_ADMIN_EMAIL_DOMAINS,
        payload: response.data
      })
      toastr.success('Create Domain', 'Authorized admin email domain created successfully!')

      history.push(history.back())
      // history.push('/authorized_admin_email_domains')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const fetchAuthorizedAdminEmailDomains = () => async dispatch => {
  await branditRails.get('/authorized_admin_email_domains').then(function (response) {
    dispatch({
      type: Types.FETCH_AUTHORIZED_ADMIN_EMAIL_DOMAINS,
      payload: response.data
    })
  })
}

export const deleteAuthorizedAdminEmailDomain = id => async dispatch => {
  await branditRails.delete(`/authorized_admin_email_domains/${id}`).then(function (response) {
    dispatch({
      type: Types.FETCH_AUTHORIZED_ADMIN_EMAIL_DOMAINS,
      payload: response.data
    })
    history.back()
  })
}

//CLIENT_IMAGES
export const createClientImage = (client_id, formValues) => async dispatch => {
  return await branditRails
    .post(`/clients/${client_id}/client_images`, formValues)
    .then(response => {
      toastr.success('Create Client Image', 'Client image created successfully!')
      dispatch({ type: Types.CREATE_CLIENT_IMAGE, payload: response.data })
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

export const fetchClientImages = client_id => async dispatch => {
  const response = await branditRails.get(`/clients/${client_id}/client_images`)
  dispatch({ type: Types.FETCH_CLIENT_IMAGES, payload: response.data })
}

export const deleteClientImage = id => async dispatch => {
  return await branditRails
    .delete(`/clients_images/${id}`)
    .then(response => {
      toastr.success('Client Image Delete', 'Image deleted successfully.!')
      dispatch({ type: Types.DELETE_CLIENT_IMAGE, payload: id })
      history.back()
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

export const editClientImage = (client_id, image_id, formValues) => async dispatch => {
  return await branditRails
    .patch(`/clients/${client_id}/client_images/${image_id}`, formValues)
    .then(response => {
      toastr.success('Edit Client Image', 'Client image edited successfully!')
      dispatch({ type: Types.EDIT_CLIENT_IMAGE, payload: response.data })
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

// Dropship Images
export const createDropshipImage = (clientId, formValues) => async dispatch => {
  return await branditRails
    .post(`/clients/${clientId}/dropship_images`, formValues)
    .then(response => {
      toastr.success('Create Dropship Image', 'Dropship image created successfully!')
      dispatch({ type: Types.CREATE_DROPSHIP_IMAGE, payload: response.data })
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

export const fetchDropshipImages = clientId => async dispatch => {
  const response = await branditRails.get(`/clients/${clientId}/dropship_images`)
  dispatch({ type: Types.FETCH_DROPSHIP_IMAGES, payload: response.data })
}

export const deleteDropshipImage = (id, clientId) => async dispatch => {
  return await branditRails
    .delete(`/clients/${clientId}/dropship_images/${id}`)
    .then(response => {
      toastr.success('Dropship Image Delete', 'Image deleted successfully.!')
      dispatch({ type: Types.DELETE_DROPSHIP_IMAGE, payload: id })
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

export const editDropshipImage = (clientId, imageId, formValues) => async dispatch => {
  return await branditRails
    .patch(`/clients/${clientId}/dropship_images/${imageId}`, formValues)
    .then(response => {
      toastr.success('Edit Dropship Image', 'Dropship image edited successfully!')
      dispatch({ type: Types.EDIT_DROPSHIP_IMAGE, payload: response.data })
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

//PRODUCTS
export const fetchClientProducts = client_id => async dispatch => {
  const response = await branditRails.get(`/clients/${client_id}/products`)
  dispatch({ type: Types.FETCH_CLIENT_PRODUCTS, payload: response.data })
}

export const fetchClientProductsNotAllDetails = client_id => async dispatch => {
  const response = await branditRails.get(`/clients_no_product_details/${client_id}/products`)
  dispatch({ type: Types.FETCH_CLIENT_PRODUCTS, payload: response.data })
}

export const addPortalProducts = (formValues, id) => async (dispatch, getState) => {
  return await branditRails
    .post(`/portals/${id}/add_products`, formValues)
    .then(response => {
      if (response.data && response.data.product_count && response.data.product_count > 0) {
        const message = `${response.data.product_count} ${response.data.message}`
        toastr.success('Success', message)
        dispatch({ type: Types.EDIT_DROPSHIP_IMAGE, payload: response.data })
      } else {
        toastr.warning('Attention', 'No products were added. Please make sure you selected at least on product.')
        dispatch({ type: Types.EDIT_DROPSHIP_IMAGE, payload: response.data })
      }
    })
    .catch(function (error) {
      toastr.error('Attention', 'An error has occurred.')
      return error.response
    })

  // const response = await branditRails.post(`/portals/${id}/add_products`, formValues)
  // dispatch({ type: Types.ADD_PORTAL_PRODUCTS, payload: response.data })
}

export const fetchPortalProducts = id => async dispatch => {
  const response = await branditRails.get(`/products?portal_id=${id}`)
  dispatch({ type: Types.FETCH_PORTAL_PRODUCTS, payload: response.data })
}

export const fetchPortalProductsWithAttributes = id => async dispatch => {
  const response = await branditRails.get(`/products_with_attributes?portal_id=${id}`)
  dispatch({ type: Types.FETCH_PORTAL_PRODUCTS, payload: response.data })
}

export const fetchPortalProductsNotAllDetails = id => async dispatch => {
  const response = await branditRails.get(`/index_no_product_details?portal_id=${id}`)
  dispatch({ type: Types.FETCH_PORTAL_PRODUCTS, payload: response.data })
}

export const updatePortalProduct = (id, data) => async dispatch => {
  const response = await branditRails.post(`/portals/${id}/update_product`, data)
  dispatch({ type: Types.UPDATE_PORTAL_PRODUCTS, payload: response.data })
}

export const removePortalProduct = (portal_id, product_id) => async dispatch => {
  await branditRails
    .delete(`/portal_product/${portal_id}/${product_id}`)
    .then(function (response) {
      dispatch({ type: Types.REMOVE_PORTAL_PRODUCT, payload: product_id })
      toastr.success('Remove Portal Product', 'Portal Product Removed successfully!')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', 'Cannot remove portal product', {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const addLocationProducts = (formValues, id) => async (dispatch, getState) => {
  if (formValues.some(isProductNeedsConfiguration)) {
    toastr.error(
      'Product Not Configured',
      'One or more products are not configured. Please configure them before adding them to a location.'
    )
    return false
  }
  const response = await branditRails.post(`/locations/${id}/add_products`, formValues)
  dispatch({ type: Types.ADD_LOCATION_PRODUCTS, payload: response.data })
  return true
}

export const fetchLocationProducts = id => async dispatch => {
  const response = await branditRails.get(`/products?location_id=${id}`)
  dispatch({ type: Types.FETCH_LOCATION_PRODUCTS, payload: response.data })
}

export const fetchLocationProductsNotAllDetails = id => async dispatch => {
  const response = await branditRails.get(`/index_no_product_details?location_id=${id}`)
  dispatch({ type: Types.FETCH_LOCATION_PRODUCTS, payload: response.data })
}

export const removeLocationProduct = (location_id, product_id) => async dispatch => {
  await branditRails
    .delete(`/location_product/${location_id}/${product_id}`)
    .then(function (response) {
      dispatch({ type: Types.REMOVE_LOCATION_PRODUCT, payload: product_id })
      toastr.success('Remove Location Product', 'Location Product Removed successfully!')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', 'Cannot remove location product', {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const createProduct = (formValues, redirect) => async (dispatch, getState) => {
  const response = await branditRails.post('/products', formValues)
  dispatch({ type: Types.CREATE_PRODUCT, payload: response.data })
  if (redirect) {
    history.push('/products')
    history.go(0)
  }
  return response
}

export const fetchProduct = (id, locationId) => async dispatch => {
  const response = await branditRails.get(`/products/${id}?location_id=${locationId}`)
  dispatch({ type: Types.FETCH_PRODUCT, payload: response.data })
}

export const fetchAggregatedProduct = (id, parent, parent_id) => async dispatch => {
  let product_details_url = ''
  if (parent === 'client') {
    product_details_url = `/products/${id}?client_id=${parent_id}`
    const response = await branditRails.get(product_details_url)
    dispatch({
      type: Types.FETCH_AGGREGATED_CLIENT_PRODUCT,
      payload: response.data
    })
  } else if (parent === 'portal') {
    product_details_url = `/products/${id}?portal_id=${parent_id}`
    const response = await branditRails.get(product_details_url)
    dispatch({
      type: Types.FETCH_AGGREGATED_PORTAL_PRODUCT,
      payload: response.data
    })
  } else if (parent === 'location') {
    product_details_url = `/products/${id}?location_id=${parent_id}`
    const response = await branditRails.get(product_details_url)
    dispatch({
      type: Types.FETCH_AGGREGATED_LOCATION_PRODUCT,
      payload: response.data
    })
  } else if (parent === 'generic') {
    product_details_url = `/products/${id}`
    const response = await branditRails.get(product_details_url)
    dispatch({
      type: Types.FETCH_AGGREGATED_GENERIC_PRODUCT,
      payload: response.data
    })
    return response
  }
}

export const editProduct =
  (id, formValues, applyHistory = true) =>
  async dispatch => {
    const response = await branditRails.patch(`/products/${id}`, formValues)
    dispatch({ type: Types.EDIT_PRODUCT, payload: response.data })
    toastr.success('Product creation', 'Product created successfully!')
    if (applyHistory) {
      history.push('/products')
      history.go(0)
    }
  }

export const editAggregatedProduct = (id, parent_id, formValues) => async dispatch => {
  const response = await branditRails.patch(
    `/products/${id}?portal_id=${parent_id}&request_for=aggregated_update`,
    formValues
  )
  dispatch({ type: Types.EDIT_PORTAL_PRODUCT, payload: response.data })
  toastr.success('Product update at Portal level', 'Product attributes updated at Portal level successfully!')
  history.back()
}

export const editAggregatedLocationProduct = (id, parent_id, formValues) => async dispatch => {
  const response = await branditRails.patch(
    `/products/${id}?location_id=${parent_id}&request_for=aggregated_update`,
    formValues
  )
  dispatch({ type: Types.EDIT_LOCATION_PRODUCT, payload: response.data })
  toastr.success('Product update at Location level', 'Product attributes updated at Location level successfully!')
  history.back()
}

export const editAggregatedGenericProduct =
  (id, parent_id, formValues, from_parent, from_parent_id, parent_2_id, parent_3_id, showToaster = true) =>
  async dispatch => {
    const response = await branditRails.patch(`/products/${id}?generic_id=1&request_for=aggregated_update`, formValues)
    dispatch({ type: Types.EDIT_GENERIC_PRODUCT, payload: response.data })
    if (showToaster) {
      toastr.success('Product update at Default level', 'Product attributes updated at Default level successfully!')
    }
  }

export const editAggregatedClientProduct = (id, parent_id, formValues) => async dispatch => {
  const response = await branditRails.patch(
    `/products/${id}?client_id=${parent_id}&request_for=aggregated_update`,
    formValues
  )
  dispatch({ type: Types.EDIT_CLIENT_PRODUCT, payload: response.data })
  toastr.success('Product update at Client level', 'Product attributes updated at Client level successfully!')
  history.back()
}

export const fetchProducts =
  (params = {}) =>
  async dispatch => {
    let urlParams = ''
    if (params.warehouse_id) {
      urlParams += `?warehouse_id=${params.warehouse_id}`
    }
    if (params.hide_generics) {
      urlParams += `?hide_generics=${params.hide_generics}`
    }
    const response = await branditRails.get(`/products${urlParams}`)
    dispatch({ type: Types.FETCH_PRODUCTS, payload: response.data })
    return response
  }

export const fetchBundleProducts = bundle_id => async dispatch => {
  const response = await branditRails.get(`/products/${bundle_id}/bundle_products`)
  dispatch({ type: Types.FETCH_BUNDLE_PRODUCTS, payload: response.data })
}

export const validateProduct = id => async dispatch => {
  const response = await branditRails.get(`/products/${id}/validate`)
  dispatch({ type: Types.VALIDATE_PRODUCT, payload: response.data })
}

// ------ LOCATIONS -------
// accepts params as an object with key value pairs
export const fetchLocations = params => async dispatch => {
  const request = {
    params
  }
  const response = await branditRails.get(`/locations`, request)
  if (response) {
    dispatch({ type: Types.FETCH_LOCATIONS, payload: response.data.locations })
    return response.data
  }
}

export const fetchLocation = id => async dispatch => {
  await branditRails.get(`/locations/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_LOCATION, payload: response.data })
  })
}

export const fetchPortalLocations = portal_id => async dispatch => {
  await branditRails.get(`/portals/${portal_id}/locations`).then(function (response) {
    return new Promise((resolve, reject) => {
      dispatch({
        type: 'FETCH_LOCATIONS',
        payload: response.data.locations,
        id: portal_id
      })
      resolve()
    })
  })
}

export const createLocation = formValues => async (dispatch, getState) => {
  await branditRails
    .post('/locations', formValues)
    .then(function (response) {
      dispatch({ type: Types.CREATE_LOCATION, payload: response.data })
      toastr.success('Create Location', 'Location created successfully!')
      history.back()
    })
    .catch(function (error) {
      switch (error.response && error.response.status) {
        case 422:
          const errorMessage = {
            message: 'Location Code has already been taken. '
          }
          dispatch({ type: Types.CREATE_SESSION_ERROR, payload: errorMessage })
          toastr.error('Location Code duplication error', 'Location code has already been taken!')
          break
        default:
          return null
      }
      dispatch({ type: 'SET_LOGIN_STATUS', payload: { message: 'FAILED' } })
    })
}

export const editLocation = (id, formValues) => async dispatch => {
  await branditRails
    .patch(`/locations/${id}`, formValues)
    .then(function (response) {
      dispatch({ type: Types.EDIT_LOCATION, payload: response.data })
      toastr.success('Update Location', 'Location updated successfully!')
      history.back()
    })
    .catch(function (error) {
      switch (error.response && error.response.status) {
        case 422:
          dispatch({
            type: Types.CREATE_SESSION_ERROR,
            payload: error.response.data
          })
          break
        default:
          return null
      }
    })
}

export const deleteLocation = id => async dispatch => {
  await branditRails.delete(`/locations/${id}`).then(function (response) {
    dispatch({ type: Types.DELETE_LOCATION, payload: id })
    toastr.success('Delete Location', 'Location deleted successfully!')
    history.back()
  })
}

export const bulkUpdateLocations = locationValues => async dispatch => {
  await branditRails.patch('/locations_bulk_update', locationValues).then(function (response) {
    return new Promise((resolve, reject) => {
      dispatch({ type: Types.BULK_UPDATE_LOCATIONS, payload: response.data })
      resolve()
    })
  })
}

export const fetchClientLocations = clientIds => async dispatch => {
  await branditRails.post('/locations/clients', clientIds).then(function (response) {
    dispatch({ type: Types.FETCH_CLIENT_LOCATIONS, payload: response.data })
  })
}

//VENDORS
export const fetchVendors = () => async dispatch => {
  const response = await branditRails.get('/vendors')
  dispatch({ type: Types.FETCH_VENDORS, payload: response.data })
}

export const createVendor = formData => async dispatch => {
  await branditRails.post(`/vendors`, formData).then(function (response) {
    dispatch({ type: Types.CREATE_VENDOR, payload: response.data })
    toastr.success('Add Vendor', 'Vendor added successfully!')
    history.back()
  })
}

export const editVendor = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .patch(`/vendors/${id}`, formValues)
    .then(function (response) {
      dispatch({ type: Types.EDIT_VENDOR, payload: response.data })
      toastr.success('Update Vendor', 'Vendor updated successfully!')
      history.back()
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const fetchVendor = id => async dispatch => {
  return await branditRails.get(`/vendors/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_VENDOR, payload: response.data })
    return response.data
  })
}

export const deleteVendor = id => async dispatch => {
  await branditRails
    .delete(`/vendors/${id}`)
    .then(function (response) {
      dispatch({ type: Types.DELETE_VENDOR, payload: id })
      toastr.success('Delete Vendor', 'Vendor deleted successfully!')
      history.back()
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', 'Cannot delete Vendor', {
          className: css({ background: 'red !important' })
        })
      }
    })
}
export const fetchDropshipVendors = () => async dispatch => {
  const response = await branditRails.get('/vendors?is_dropship=true')
  dispatch({ type: Types.FETCH_VENDORS, payload: response.data })
}

// *******************
//    Categories
// *******************

export const createPortalCategory = (portal_id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post(`/portals/${portal_id}/categories`, formValues)
    .then(function (response) {
      dispatch({ type: Types.CREATE_CATEGORY, payload: response.data })
      toastr.success('Portal Category', 'Portal Category created successfully!')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const createCategoryProducts = (category_id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post(`/categories/${category_id}/addProducts`, formValues)
    .then(function (response) {
      dispatch({
        type: Types.CREATE_CATEGORY_PRODUCTS,
        payload: response.data
      })
      toastr.success('Add Products To Category', 'Successfully added products!')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const deleteCategory = id => async dispatch => {
  await branditRails.delete(`/categories/${id}`).then(function (response) {
    dispatch({ type: Types.DELETE_CATEGORY, payload: id })
    history.back()
  })
}

export const editCategoryVisibility = (value, id) => async dispatch => {
  await branditRails.patch(`/categories/${id}?enabled=${value}`).then(function (response) {
    dispatch({
      type: Types.EDIT_CATEGORY_VISIBILITY,
      payload: response.data
    })
  })
}

export const fetchCategory = id => async dispatch => {
  await branditRails.get(`/categories/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_CATEGORY, payload: response.data })
  })
}

export const updateCategory = (formData, id) => async dispatch => {
  await branditRails.patch(`/categories/${id}`, formData).then(response => {
    dispatch({ type: 'UPDATE_CATEGORY', payload: response.data })
  })
}

export const removeCategoryFromLocation = categoryLocationId => async dispatch => {
  await branditRails.delete(`/remove_category_from_location/${categoryLocationId}`).then(response => {
    dispatch({
      type: 'SET_LOCATION_CATEGORIES',
      payload: response.data.location_categories
    })
    dispatch({
      type: 'SET_PORTAL_CATEGORIES',
      payload: response.data.portal_categories
    })
    toastr.success('Category Deleted', 'Category Deleted Successfully')
  })
}

export const fetchPortalCategories = (portal_id, fetch_type) => async dispatch => {
  await branditRails.get(`/portals/${portal_id}/categories?fetch_type=${fetch_type}`).then(function (response) {
    dispatch({
      type: Types.FETCH_PORTAL_CATEGORIES,
      payload: response.data
    })
  })
}

export const fetchPortalCategoriesFromLocation = locationId => async dispatch => {
  await branditRails.get(`/portal_categories_from_location/${locationId}`).then(function (response) {
    dispatch({
      type: Types.FETCH_PORTAL_CATEGORIES,
      payload: response.data
    })
  })
}

// Add SKUs to categories
export const addSkusToPortalCategories = (skus, categories) => async dispatch => {
  const [skuIds, categoryIds] = [skus.map(s => s.id), categories.map(l => l.id)]
  if (skus.some(isProductNeedsConfiguration)) {
    toastr.error(
      'Product Not Configured',
      'One or more products are not configured. Please configure them before adding them to a category.'
    )
    return
  }
  return await branditRails.post(`/products/add_to_categories`, { skuIds, categoryIds }).then(response => {
    if (response.data && response.data.errors) {
      response.data.errors.map(e => toastr.error('Error', e.message))
      return false
    } else {
      toastr.success('Success', 'Products added to categories successfully!')
      return true
    }
  })
}

export const addLocationToPortalCategory = (category_id, location_id) => async dispatch => {
  await branditRails.post(`/add_location_to_portal_category`, { category_id, location_id }).then(response => {
    if (response.data.success == true) {
      dispatch({
        type: 'SET_LOCATION_CATEGORIES',
        payload: response.data.location_categories
      })
      dispatch({
        type: 'SET_PORTAL_CATEGORIES',
        payload: response.data.portal_categories
      })
    } else {
      toastr.error('Error', response.data.message, {
        className: css({ background: 'red !important' })
      })
    }
  })
}

export const fetchLocationCategories = locationId => async dispatch => {
  await branditRails.get(`/location_categories/${locationId}`).then(response => {
    dispatch({ type: 'SET_LOCATION_CATEGORIES', payload: response.data })
  })
}

export const fetchOveriddenCategory = (id, location_id) => async dispatch => {
  await branditRails.get(`/categories/overidden_category/${id}/${location_id}`).then(function (response) {
    dispatch({
      type: Types.FETCH_OVERIDDEN_CATEGORY,
      payload: response.data
    })
  })
}

export const createCategoryLocation = formValues => async dispatch => {
  await branditRails
    .post(`categories/override`, formValues)
    .then(function (response) {
      dispatch({ type: Types.OVERRIDE_CATEGORY, payload: response.data })
      toastr.success('Override Category', 'Category Overridden Successfully')
      history.back()
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const fetchPortalCategoryProducts = (cat_id, portal_id, show_category_products) => async dispatch => {
  await branditRails
    .get(`/products?portal_id=${portal_id}&categories=${cat_id}&request_type=${show_category_products}`)
    .then(function (response) {
      dispatch({
        type: Types.FETCH_CATEGORY_PRODUCTS,
        payload: response.data
      })
    })
}

export const editPortalCategoryProducts = (formValues, category_id) => async dispatch => {
  return await branditRails
    .post(`/categories/${category_id}/editProductCategories`, formValues)
    .then(function (response) {
      toastr.success('Successful', 'Product Sort Order Updated Successfully')
      return response
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        if (error.response.data.error) {
          toastr.error('Error', error.response.data.error, {
            className: css({ background: 'red !important' })
          })
        } else {
          error.response.data.sort_order.map(error => {
            toastr.error('Error', error, {
              className: css({ background: 'red !important' })
            })
          })
        }
      }
      return error
    })
}

export const removePortalCategoryProduct = formValues => async dispatch => {
  const category_id = formValues.get('category_id')
  const product_id = formValues.get('product_id')
  return await branditRails
    .delete(`/categories/remove_product/${product_id}/${category_id}`)
    .then(function (response) {
      toastr.success('Successful', 'Product Removed from Category Successfully')
      return response
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        if (error.response.data.error) {
          toastr.error('Error', error.response.data.error, {
            className: css({ background: 'red !important' })
          })
        } else {
          error.response.data.sort_order.map(error => {
            toastr.error('Error', error, {
              className: css({ background: 'red !important' })
            })
          })
        }
      }
      return error
    })
}

// **************************************
// Product ConfigurableVariants
// **************************************

export const fetchProductConfigurableVariants = () => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  dispatch({ type: Types.CREATE_SESSION_ERROR, payload: '' })

  const response = await branditRails.get('/configurable_variants')
  dispatch({
    type: Types.FETCH_PRODUCT_CONFIGURABLE_VARIANTS,
    payload: response.data
  })
}

export const fetchProductConfigurableVariantsByProduct = productId => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  dispatch({ type: Types.CREATE_SESSION_ERROR, payload: '' })
  const response = await branditRails.get(`/configurable_variants/product/${productId}`)
  dispatch({
    type: Types.FETCH_PRODUCT_CONFIGURABLE_VARIANTS,
    payload: response.data
  })
}

export const fetchProductAssignedVariants = id => async dispatch => {
  const response = await branditRails.get(`/products/${id}/variants`)
  dispatch({
    type: Types.FETCH_PRODUCT_ASSIGNED_VARIANTS,
    payload: response.data
  })
}

export const createProductConfigurableVariant = formValues => async (dispatch, getState) => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  return await branditRails
    .post('/configurable_variants', formValues)
    .then(function (response) {
      dispatch({
        type: Types.CREATE_PRODUCT_CONFIGURABLE_VARIANT,
        payload: response.data
      })
      toastr.success('Configurable Variant', 'Configurable variant created successfully!')
      return response
    })
    .catch(function (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach(message => {
          return toastr.error('Error', message)
        })
      }
      return error
    })
}

export const fetchProductConfigurableVariant = id => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  dispatch({ type: Types.CREATE_SESSION_ERROR, payload: '' })
  await branditRails.get(`/configurable_variants/${id}`).then(function (response) {
    dispatch({
      type: Types.FETCH_PRODUCT_CONFIGURABLE_VARIANT,
      payload: response.data
    })
  })
}

export const editProductConfigurableVariant = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  dispatch({ type: Types.CREATE_SESSION_ERROR, payload: '' })

  return await branditRails
    .patch(`/configurable_variants/${id}`, formValues)
    .then(function (response) {
      dispatch({
        type: Types.EDIT_PRODUCT_CONFIGURABLE_VARIANT,
        payload: response.data
      })
      toastr.success('Update Configurable Variant', 'Configurable variant updated successfully!')
      return response
    })
    .catch(function (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach(message => {
          return toastr.error('Error', message)
        })
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
      return error
    })
}

export const deleteProductConfigurableVariant = id => async dispatch => {
  await branditRails.delete(`/configurable_variants/${id}`).then(function (response) {
    if (response.data && response.data.code === 422) {
      toastr.error(response.data.message)
    } else {
      dispatch({
        type: Types.DELETE_PRODUCT_CONFIGURABLE_VARIANT,
        payload: id
      })
      toastr.success('Configurable Variant Delete', 'Configurable variant deleted successfully!')
    }
    history.back()
  })
}

export const bulkUpdateConfigurableVariants = configurableVariantValues => async dispatch => {
  await branditRails.patch('/configurable_variants_bulk_update', configurableVariantValues).then(function (response) {
    return new Promise((resolve, reject) => {
      dispatch({
        type: Types.BULK_UPDATE_CONFIGURABLE_VARIANTS,
        payload: response.data
      })
      resolve()
    })
  })
}

export const fetchLocationSubCategories = (id, location_id) => async dispatch => {
  await branditRails.get(`/location_sub_categories/${id}/${location_id}`).then(function (response) {
    dispatch({
      type: Types.FETCH_LOCATION_SUB_CATEGORIES,
      payload: response.data,
      parent_id: id
    })
  })
}

export const fetchLocationCategoryProducts = (id, location_id) => async dispatch => {
  await branditRails.get(`/location_category_products/${id}/${location_id}`).then(function (response) {
    dispatch({
      type: Types.FETCH_LOCATION_CATEGORY_PRODUCTS,
      payload: response.data,
      parent_id: id
    })
  })
}

// **************************************
// Product ConfigurableOptions
// **************************************
export const createVariantConfigurableOptionProductDetails = (configurable_option_id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post(
      `/configurable_variants/0/configurable_options/${configurable_option_id}/configurable_option_details`,
      formValues
    )
    .then(function (response) {
      dispatch({
        type: Types.CREATE_CONFIGURABLE_OPTION_DETAILS,
        payload: response.data
      })
      toastr.success('Update Opton Details', 'Option updated successfully!')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const createVariantConfigurableOption = (configurable_variant_id, formValues) => async (dispatch, getState) => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  return await branditRails
    .post(`/configurable_variants/${configurable_variant_id}/configurable_options`, formValues)
    .then(function (response) {
      dispatch({
        type: Types.CREATE_CONFIGURABLE_OPTION,
        payload: response.data
      })
      toastr.success('Configurable Option', 'Configurable option created successfully!')
      return response
    })
    .catch(function (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach(message => {
          return toastr.error('Error', message)
        })
      }
      return error
    })
}

export const fetchVariantConfigurableOptions = configurable_variant_id => async dispatch => {
  await branditRails
    .get(`/configurable_variants/${configurable_variant_id}/configurable_options`)
    .then(function (response) {
      dispatch({
        type: Types.FETCH_CONFIGURABLE_OPTIONS,
        payload: response.data
      })
    })
}

export const fetchVariantConfigurableOption = (configurable_variant_id, id) => async dispatch => {
  await branditRails
    .get(`/configurable_variants/${configurable_variant_id}/configurable_options/${id}`)
    .then(function (response) {
      dispatch({
        type: Types.FETCH_CONFIGURABLE_OPTION,
        payload: response.data
      })
    })
}

export const editVariantConfigurableOption = (configurable_variant_id, id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  dispatch({ type: Types.CREATE_SESSION_ERROR, payload: '' })

  return await branditRails
    .patch(`/configurable_variants/${configurable_variant_id}/configurable_options/${id}`, formValues)
    .then(function (response) {
      dispatch({
        type: Types.EDIT_CONFIGURABLE_OPTION,
        payload: response.data
      })
      toastr.success('Update Configurable Option', 'Configurable option updated successfully!')
      return response
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
      return error
    })
}

export const deleteVariantConfigurableOption = (configurable_variant_id, id) => async dispatch => {
  await branditRails
    .delete(`/configurable_variants/${configurable_variant_id}/configurable_options/${id}`)
    .then(function (response) {
      if (response.data && response.data.code === 422) {
        toastr.error(response.data.message)
      } else {
        dispatch({ type: Types.DELETE_CONFIGURABLE_OPTION, payload: id })
        toastr.success('Configurable Option Delete', 'Configurable option deleted successfully!')
      }
      history.back()
    })
}

export const fetchSkuPreview = (id, formValues) => async dispatch => {
  await branditRails
    .post(`/products/${id}/variant_sku_preview`, formValues)
    .then(function (response) {
      dispatch({ type: Types.FETCH_SKU_PREVIEW, payload: response.data })
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const createProductVariants = (id, formValues) => async dispatch => {
  return await branditRails
    .post(`/products/${id}/variant_sku_create`, formValues)
    .then(function (response) {
      toastr.success('Product Skus Created', 'Product skus created successfully!')
      dispatch({ type: Types.CREATE_VARIANT_SKU, payload: response.data })
      return response
    })
    .catch(function (errors) {
      if (errors.response.status == 422 && errors.response.data?.errors) {
        errors.response.data.errors.map(error => {
          toastr.error('Error', error, {
            className: css({ background: 'red !important' })
          })
          dispatch({ type: Types.CREATE_SESSION_ERROR, payload: error })
        })
      }
    })
}

export const clearConfigWizardForm = () => async dispatch => {
  dispatch({ type: Types.CLEAR_CONFIG_WIZARD_FORM, payload: '' })
}

export const fetchVariantProducts = id => async dispatch => {
  const response = await branditRails.get(`/configurable_variants/${id}/products`)
  dispatch({ type: Types.FETCH_VARIANT_PRODUCTS, payload: response.data })
}

// **************************************
// User
// **************************************

export const fetchUser = id => async dispatch => {
  dispatch({ type: 'CLEAR_USER', payload: id })
  await branditRails.get(`users/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_USER, payload: response.data })
  })
}

export const verifyUserByEmailAndClientId = async (email, clientId) => {
  try {
    let url = `users/verify_user_existence_by_email?email=${encodeURIComponent(email)}`
    if (clientId) {
      url += `&client_id=${encodeURIComponent(clientId)}`
    }
    const response = await branditRails.get(url)
    return {
      exists: response.data.exists,
      userId: response.data.exists ? response.data.user_id : null,
      clientId: response.data.exists ? response.data.client_id : null,
      isLinkedToCustomer: response.data.is_linked_to_admin,
      message: response.data.message
    }
  } catch (error) {
    console.error('Error fetching user by email:', error)
    throw error
  }
}

export const fetchUsers = queryParams => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  const params = `?skip=${queryParams.skip}&limit=${queryParams.limit}&sortInfo=${JSON.stringify(
    queryParams.sortInfo
  )}&filterValue=${JSON.stringify(queryParams.filterValue)}`

  return await branditRails.get(`users/${params}`).then(function (response) {
    const totalCount = response.headers['x-total-count']
    return Promise.resolve({
      data: response.data,
      count: parseInt(totalCount)
    })
  })
}

export const fetchUsersGrid = queryParams => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  const params = setParams(queryParams)
  return await branditRails.get(`users_index_grid/${params}`).then(function (response) {
    const totalCount = response.headers['x-total-count']
    return Promise.resolve({
      data: response.data,
      count: parseInt(totalCount)
    })
  })
}

export const importCustomers = formValues => async (dispatch, getState) => {
  return await branditRails
    .post(
      `/portals/import_users/${formValues.get('portal_code')}/${formValues.get('imported')}/${formValues.get(
        'is_full_import'
      )}/${formValues.get('is_from_interface')}`
    )
    .then(response => {
      const duplicatedUsers = response.data.users_to_import - response.data.users_imported
      return response
    })
    .catch(function (error) {
      toastr.error('Attention', 'An error has occurred.')
      return error.response
    })
}

export const createUser = formValues => async (dispatch, getState) => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  return await branditRails
    .post('/users', formValues)
    .then(function (response) {
      dispatch({ type: Types.CREATE_USER, payload: response.data })
      toastr.success('Create Customer', 'Customer created successfully!', {
        className: css({ background: 'green !important' })
      })
      return response
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', error.response.data, {
          className: css({ background: 'red !important' })
        })
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const editUser = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .patch(`/users/${id}`, formValues)
    .then(function (response) {
      dispatch({ type: Types.EDIT_USER, payload: response.data })
      toastr.success('Update Customer', 'Customer updated successfully!')
      history.back()
    })
    .catch(function (error) {
      if (error.response.data[0].status == 0) {
        const error_message = error.response.data[0].error_message
        alert(error_message)
        const errorObj = {
          message: error_message
        }
        dispatch({ type: Types.CREATE_SESSION_ERROR, payload: errorObj })
        return
      }

      if (error.response.status == 422) {
        toastr.error('Error', error.response.data, {
          className: css({ background: 'red !important' })
        })
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const clearUser = id => async dispatch => {
  dispatch({ type: 'CLEAR_USER', payload: id })
}

export const editUserDetails = (id, formValues) => dispatch => {
  dispatch({ type: 'CLEAR_USER', payload: id })

  return branditRails
    .patch(`/users/${id}/edit_details`, formValues)
    .then(response => {
      toastr.success('Update Customer', 'Customer updated successfully!')
      dispatch({ type: Types.FETCH_USER, payload: response.data })
      return true
    })
    .catch(e => {
      toastr.error('Error updating customer', e.response.data.error)
      return false
    })
}

export const toggleActiveCustomer = (userId, isActive) => async dispatch => {
  return branditRails.patch(`/users/${userId}/edit_details`, { is_active: isActive }).then(() => {
    toastr.success('Update Customer', 'Customer updated successfully!')
    dispatch({ type: Types.SET_CUSTOMER_ACTIVE, payload: { userId, data: isActive } })
    return true
  })
}

export const initiatePasswordReset =
  (portalId = null) =>
  async dispatch => {
    dispatch({ type: Types.CLEAR_ERRORS, payload: '' })

    // toastr.warning(
    //   "Initiate Password Reset",
    //   "Depending on the number of active customers, the request may take a long time!"
    // );
    const endpoint = 'initiate_password_reset'

    await branditRails.patch(`${endpoint}${portalId ? `?portal_id=${portalId}` : ''}`).then(function (response) {
      toastr.success('Initiate Password Reset', `Password has been reset for ${response.data.length} users!`)
      history.back()
    })
  }

export const deleteUser = id => async dispatch => {
  await branditRails
    .delete(`users/${id}`)
    .then(function (response) {
      dispatch({ type: Types.DELETE_USER, payload: id })
      toastr.success('Delete Customer', 'Customer deleted successfully!')
      history.back()
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', 'Cannot delete current user', {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const createCustomerGroup = formValues => async (dispatch, getState) => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post('/customer_groups', formValues)
    .then(function (response) {
      dispatch({ type: Types.CREATE_CUSTOMER_GROUP, payload: response.data })
      toastr.success('Create Customer Group', 'Customer group created successfully!')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const createCustomerGroupRule = formValues => async (dispatch, getState) => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails.post('/rules', formValues).then(function (response) {
    dispatch({
      type: Types.CREATE_CUSTOMER_GROUP_RULE,
      payload: response.data
    })
    toastr.success('Create Customer Group Rule', 'Customer group rule created successfully!')
  })
}

export const editCustomerGroupRule = (id, formValues) => async dispatch => {
  await branditRails.patch(`/rules/${id}`, formValues).then(function (response) {
    dispatch({
      type: Types.EDIT_CUSTOMER_GROUP_RULE,
      payload: response.data
    })
    toastr.success('Update Customer Group Rule', 'Customer Group Rule updated successfully!')
  })
}

export const bulkCreateRuleHiddenProducts = formValues => async (dispatch, getState) => {
  await branditRails.post('/rule_hidden_products', formValues).then(function (response) {
    dispatch({
      type: Types.FETCH_CUSTOMER_GROUP_RULE,
      payload: response.data
    })
    toastr.success('Create Rule Hidden Products', 'Rule Hidden Products created successfully!')
  })
}

export const removeRuleHiddenProduct = (rule_id, product_id) => async dispatch => {
  await branditRails
    .delete(`/remove_rule_hidden_product/${rule_id}/${product_id}`)
    .then(function (response) {
      dispatch({
        type: Types.FETCH_CUSTOMER_GROUP_RULE,
        payload: response.data
      })
      toastr.success('Delete Rule Hidden Product', 'Rule Hidden Product deleted successfully!')
      /* Uncomment to use JqxGrid instead of BootstrapTable */
      // history.back();
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', 'Cannot delete Rule Hidden Product', {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const bulkCreateRuleHiddenCategories = formValues => async (dispatch, getState) => {
  await branditRails.post('/rule_hidden_categories', formValues).then(function (response) {
    dispatch({
      type: Types.FETCH_CUSTOMER_GROUP_RULE,
      payload: response.data
    })
    toastr.success('Create Rule Hidden Categories', 'Rule Hidden Categories created successfully!')
  })
}

export const removeRuleHiddenCategory = (rule_id, category_id) => async dispatch => {
  await branditRails
    .delete(`/remove_rule_hidden_category/${rule_id}/${category_id}`)
    .then(function (response) {
      dispatch({
        type: Types.FETCH_CUSTOMER_GROUP_RULE,
        payload: response.data
      })
      toastr.success('Delete Rule Hidden Category', 'Rule Hidden Category deleted successfully!')
      /* Uncomment to use JqxGrid instead of BootstrapTable */
      // history.back();
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', 'Cannot delete Rule Hidden Category', {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const bulkAddArtworksToRule = formValues => async (dispatch, getState) => {
  await branditRails.post('/rule_hidden_artworks', formValues).then(function (response) {
    dispatch({
      type: Types.FETCH_CUSTOMER_GROUP_RULE,
      payload: response.data
    })
    toastr.success('Create Rule Hidden Artworks', 'Rule Hidden Artworks created successfully!')
  })
}

export const removeRuleHiddenArtwork = (rule_id, artwork_id) => async dispatch => {
  await branditRails
    .delete(`/remove_rule_hidden_artwork/${rule_id}/${artwork_id}`)
    .then(function (response) {
      dispatch({
        type: Types.FETCH_CUSTOMER_GROUP_RULE,
        payload: response.data
      })
      toastr.success('Delete Rule Hidden Artwork', 'Rule Hidden Artwork deleted successfully!')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', 'Cannot delete Rule Hidden Artwork', {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const fetchPortalCustomerGroupRules = portal_id => async dispatch => {
  await branditRails.post('/rules/portal_rules', { portal_id }).then(function (response) {
    dispatch({
      type: Types.FETCH_PORTAL_CUSTOMER_GROUP_RULES,
      payload: response.data
    })
  })
}

export const fetchPortalUsers = portal_id => async dispatch => {
  await branditRails.get(`/portals/${portal_id}/portal_users`).then(function (response) {
    dispatch({ type: Types.FETCH_PORTAL_USERS, payload: response.data })
  })
}

export const fetchProductPortalsUsers = product_id => async dispatch => {
  await branditRails.get(`products/${product_id}/portals_users`).then(function (response) {
    dispatch({ type: Types.FETCH_PORTAL_USERS, payload: response.data })
  })
}

export const bulkUpdateCustomerGroupRules = (customer_group_id, formValues) => async dispatch => {
  await branditRails
    .post(`/customer_groups/${customer_group_id}/bulk_assign_rules`, formValues)
    .then(function (response) {
      dispatch({ type: Types.FETCH_CUSTOMER_GROUP, payload: response.data })
    })
}

export const bulkUpdateCustomerGroupUsers = (customer_group_id, formValues) => async dispatch => {
  await branditRails
    .post(`/customer_groups/${customer_group_id}/bulk_assign_users`, formValues)
    .then(function (response) {
      dispatch({ type: Types.FETCH_CUSTOMER_GROUP, payload: response.data })
    })
}

export const fetchCustomerGroup = id => async dispatch => {
  await branditRails.get(`/customer_groups/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_CUSTOMER_GROUP, payload: response.data })
  })
}

export const fetchCustomerGroups = portal_id => async dispatch => {
  await branditRails.get(`/customer_groups?portal_id=${portal_id}`).then(function (response) {
    dispatch({ type: Types.FETCH_CUSTOMER_GROUPS, payload: response.data })
  })
}

export const fetchCustomerGroupsForProduct = product_id => async dispatch => {
  await branditRails.get(`/customer_groups?product_id=${product_id}`).then(function (response) {
    dispatch({ type: Types.FETCH_CUSTOMER_GROUPS, payload: response.data })
  })
}

export const fetchUserPortalsCustomerGroups = user_id => async dispatch => {
  await branditRails.get(`/user_portals_customer_groups?user_id=${user_id}`).then(function (response) {
    dispatch({
      type: Types.FETCH_USER_PORTALS_CUSTOMER_GROUPS,
      payload: response.data
    })
  })
}

export const fetchPortalCustomerGroups = (userId, portalId) => async dispatch =>
  await branditRails.get(`/portal_customer_groups?portal_id=${portalId}&user_id=${userId}`).then(function (response) {
    dispatch({
      type: Types.FETCH_CUSTOMER_CUSTOMER_GROUPS,
      payload: { portalId, userId, data: response.data }
    })
    return response.data
  })

export const fetchCustomerPortals = userId => async dispatch => {
  branditRails.get(`/user_portals/${userId}`).then(response => {
    dispatch({ type: Types.FETCH_CUSTOMER_PORTALS, payload: response.data })
  })
}

export const UpdateUserPortalCustomerGroup = formValues => async dispatch => {
  await branditRails.patch(`/user_portal_customer_group`, formValues).then(function (response) {
    dispatch({
      type: Types.FETCH_USER_PORTALS_CUSTOMER_GROUPS,
      payload: response.data
    })
    toastr.success('Update User Portal Customer Group', 'User Portal Customer Group updated successfully!')
  })
}

export const fetchCustomerGroupRule = id => async dispatch => {
  await branditRails.get(`/rules/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_CUSTOMER_GROUP_RULE, payload: response.data })
  })
}

export const fetchCustomerGroupsForUser = id => async dispatch => {
  await branditRails.get(`/customer_groups/user/${id}`).then(function (response) {
    dispatch({
      type: Types.FETCH_CUSTOMER_GROUPS_FOR_USER,
      payload: response.data,
      user_id: id
    })
  })
}

////// WAREHOUSES //////
export const fetchWarehouses =
  (clientId = null, productId = null) =>
  async dispatch => {
    dispatch({ type: 'CLEAR_WAREHOUSES' })
    let params = ''
    if (clientId) {
      params = `?client_id=${clientId}`
    } else if (productId) {
      params = `?product_id=${productId}`
    }
    await branditRails.get(`/warehouses${params}`).then(function (response) {
      dispatch({ type: Types.FETCH_WAREHOUSES, payload: response.data })
    })
  }

export const fetchProductWarehouses =
  (productId, clientId = null) =>
  async dispatch => {
    let params = ''
    if (clientId) {
      params = `?client_id=${clientId}`
    }
    await branditRails.get(`/products/${productId}/warehouses${params}`).then(response => {
      dispatch({ type: Types.FETCH_WAREHOUSES, payload: response.data })
    })
  }

export const fetchWarehousesByProducts = skus => async dispatch => {
  await branditRails.post('/warehouses/filter_by_products', skus).then(function (response) {
    dispatch({
      type: Types.FETCH_WAREHOUSES_FILTERED_BY_PRODUCTS,
      payload: response.data
    })
  })
}

export const createWarehouse = formValues => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post(`/warehouses`, formValues)
    .then(function (response) {
      dispatch({ type: Types.CREATE_WAREHOUSE, payload: response.data })
      toastr.success('Create Stock Warehouse', 'Stock Warehouse created successfully!')
      history.back()
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_VALIDATION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const createProductWarehouse = (formValues, clientId) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  let params = ''
  if (clientId) {
    params = `?client_id=${clientId}`
  }
  await branditRails
    .post(`/warehouses`, formValues)
    .then(function (response) {
      dispatch({ type: Types.CREATE_WAREHOUSE, payload: response.data })
      toastr.success('Create Stock Warehouse', 'Stock Warehouse created successfully!', {
        className: css({ background: 'green !important' })
      })
      history.back()
    })
    .catch(function (error) {
      if (error.response.status === 406) {
        dispatch({
          type: Types.CREATE_VALIDATION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const fetchWarehouse = (id, options) => async dispatch => {
  if (!id) return
  let query_params = ''
  if (options && options.sub_order_id) {
    query_params = `?sub_order_id=${options.sub_order_id}`
  }
  return await branditRails.get(`/warehouses/${id}${query_params}`).then(function (response) {
    dispatch({ type: Types.FETCH_WAREHOUSE, payload: response.data })
    return response.data
  })
}

export const fetchSuborderWarehouse = subOrderId => async dispatch => {
  return await branditRails.get(`/sub_order_warehouse/${subOrderId}`).then(function (response) {
    dispatch({ type: Types.FETCH_WAREHOUSE, payload: response.data })
    return response.data
  })
}

export const editWarehouse = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .patch(`/warehouses/${id}`, formValues)
    .then(function (response) {
      dispatch({ type: Types.EDIT_WAREHOUSE, payload: response.data })
      toastr.success('Update Stock Warehouse', 'Stock warehouse updated successfully!')
      history.back()
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const createWarehouseStock = formValues => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post(`/warehouse_stocks`, formValues)
    .then(function (response) {
      dispatch({ type: Types.CREATE_WAREHOUSE_STOCK, payload: response.data })
      toastr.success('Create Warehouse Stock', 'Stock added to warehouse successfully!')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const fetchStocks = (subOrderWithItems, warehouses, skus) => async dispatch => {
  const result = {}
  subOrderWithItems.sub_order_items.map(item => {
    const itemProductSku = Object.values(skus).find(sku => sku.sku == item.product_sku)
    if (itemProductSku) {
      const warehouse = warehouses[subOrderWithItems.warehouse_id]
      if (warehouse && warehouse.warehouse_stocks) {
        const itemWarehouseStock = warehouse.warehouse_stocks.find(e => e.product_sku_id == itemProductSku.id)
        if (itemWarehouseStock) {
          const qtyOnHand = itemWarehouseStock.quantity // - itemWarehouseStock.committed
          const itemQuantity = item.quantity * item.bundle_qty
          const statusColor = mapQtyToColor(qtyOnHand, itemQuantity)
          result[item.id] = statusColor
        }
      }
    }
  })
  dispatch({ type: 'FETCH_STOCKS', payload: result })
}

export const fetchWarehouseStock = id => async dispatch => {
  return await branditRails
    .get(`/warehouse_stocks/${id}`)
    .then(response => {
      dispatch({ type: Types.FETCH_WAREHOUSE_STOCK, payload: response.data })
      return response.data
    })
    .catch(error => {
      toastr.error('Fetch Warehouse Stock', 'An error occurred while fetching the warehouse stock.')
    })
}

export const fetchInventoryLog = productSkuId => async dispatch => {
  return await branditRails
    .get(`/inventory_logs/${productSkuId}/find_by_product_sku_id`)
    .then(response => {
      dispatch({ type: Types.FETCH_INVENTORY_LOG, payload: { ...response.data } })
      return response.data
    })
    .catch(error => {
      toastr.error('Fetch Inventory Log', 'An error occurred while fetching the inventory log.')
    })
}

// takes product ID and warehouse ID, adds warehouse_stocks entries for all child skus
export const addProductToWarehouse = (warehouseId, productId) => async dispatch => {
  await branditRails.post(`/warehouses/${warehouseId}/add_product/${productId}`).then(response => {
    dispatch({ type: Types.FETCH_WAREHOUSE, payload: response.data })
    toastr.success('Update Warehouse Stock', 'Warehouse stocks updated successfully!')
  })
}

export const editWarehouseStock = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .patch(`/warehouse_stocks/${id}`, formValues)
    .then(function (response) {
      dispatch({ type: Types.EDIT_WAREHOUSE_STOCKS, payload: response.data })
      toastr.success('Update Warehouse Stock', 'Warehouse stocks updated successfully!')
    })
    .catch(function (error) {
      if (error.response && error.response.status === 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const deleteWarehouse = id => async dispatch => {
  await branditRails
    .delete(`/warehouses/${id}`)
    .then(function (response) {
      dispatch({ type: Types.DELETE_WAREHOUSE, payload: id })
      toastr.success('Delete Warehouse', 'Warehouse deleted successfully!')
      history.back()
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', 'Cannot delete current warehouse', {
          className: css({ background: 'red !important' })
        })
      } else if (error.response.status == 403) {
        toastr.error('Error', 'Cannot delete warehouse: warehouse still contains stock', {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const fetchShipFromAddresses = () => async dispatch => {
  await branditRails.get('/ship_from_addresses').then(function (response) {
    dispatch({ type: Types.FETCH_SHIP_FROM_ADDRESSES, payload: response.data })
  })
}

/// PRODUCT SKUS ////////
export const fetchProductSkusSorted =
  (product = null, warehouseId = null, productIds = null) =>
  async dispatch => {
    let params = ''
    if (product) {
      params = `?for_product=${product.id}`
    } else if (warehouseId) {
      params = `?warehouse_id=${warehouseId}`
    } else if (productIds) {
      params = `?for_products=${productIds}`
    }
    await branditRails.get(`/product_skus/sorted${params}`).then(function (response) {
      dispatch({
        type: Types.FETCH_PRODUCT_SKUS_SORTED,
        payload: response.data
      })
    })
  }

export const toggleProductSkuFromWarehouseView = (id, warehouseId) => async dispatch => {
  await branditRails
    .patch(`/product_skus/${id}/hide`, {
      warehouse_id: warehouseId
    })
    .then(response => {
      dispatch({ type: Types.TOGGLE_PRODUCT_SKU_VISIBLITY, payload: id })
    })
}

export const fetchProductSku = id => async dispatch => {
  await branditRails.get(`/product_skus/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_PRODUCT_SKU, payload: response.data, id })
  })
}

export const createProductSkuDetails = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post(`/product_skus/${id}/details`, formValues)
    .then(function (response) {
      dispatch({ type: Types.FETCH_PRODUCT_SKU, payload: response.data })
      toastr.success('Create SKU Override', 'SKU override created')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const fetchWarehouseProductSkus =
  (warehouse_id, search = '', page = 0) =>
  async dispatch => {
    const response = await branditRails.get(`product_skus/warehouse_id/${warehouse_id}?search=${search}&page=${page}`)
    dispatch({
      type: Types.FETCH_PRODUCT_SKUS,
      payload: response.data.product_skus
    })
    return response.data
  }

//USER CARTS
export const fetchUserCarts = user_id => async dispatch => {
  await branditRails.get(`/user_carts/${user_id}`).then(function (response) {
    dispatch({ type: Types.FETCH_CARTS, payload: response.data })
  })
}

export const deleteUserCart = id => async dispatch => {
  await branditRails
    .delete(`/user_carts/${id}`)
    .then(resp => {
      toastr.success('Delete Cart', 'Cart deleted successfully!')
    })
    .catch(error => {
      toastr.error('Error', error.response.data)
    })
}

//CREDITS
export const fetchCreditBalances = (portalId, periodId, page, formValues) => async dispatch => {
  await branditRails.post(`/credit_balances/${portalId}/${periodId}/${page}`, formValues).then(function (response) {
    dispatch({ type: Types.FETCH_CREDIT_BALANCES, payload: response.data })
  })
}

//external accounts
export const fetchExternalAccounts = (portalId, page, rowsPerPage, formValues) => async dispatch => {
  await branditRails.post(`/external_accounts/${portalId}/${page}/${rowsPerPage}`, formValues).then(response => {
    if (response.data) {
      dispatch({
        type: Types.FETCH_EXTERNAL_ACCOUNTS,
        payload: response.data
      })
    }
  })
}

export const fetchCredits = (id, portalId, locationId, periodId) => async dispatch => {
  await branditRails.get(`/credits/${id}/${portalId}/${locationId}/${periodId}`).then(function (response) {
    dispatch({ type: Types.FETCH_CREDITS, payload: response.data })
  })
}

export const createCredit = formValues => async (dispatch, getState) => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post('credits', formValues)
    .then(function (response) {
      dispatch({ type: Types.CREATE_CREDITS, payload: response.data })
      toastr.success('Create Credit', 'Credit created successfully!')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', 'Check that ECPID and ECLID are set properly', {
          className: css({ background: 'red !important' })
        })
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const uploadCredits = formValues => async (dispatch, getState) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post('upload_credits', formValues, config)
    .then(function (response) {
      dispatch({ type: Types.UPLOAD_RESULTS, payload: response.data })
    })
    .catch(function (error) {
      dispatch({ type: Types.CREATE_SESSION_ERROR, payload: error.response })
    })
}

export const clearUploadedResults = () => async dispatch => {
  dispatch({ type: 'CLEAR_UPLOADED_RESULTS' })
}

export const uploadCreditRedemption = formValues => async (dispatch, getState) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post('upload_credit_redemption', formValues, config)
    .then(function (response) {
      dispatch({ type: Types.UPLOAD_RESULTS, payload: response.data[0] })
    })
    .catch(function (error) {
      dispatch({ type: Types.CREATE_SESSION_ERROR, payload: error.response })
    })
}

export const updateExternalIds = formValues => async (dispatch, getState) => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post('update_external_ids', formValues)
    .then(function (response) {
      dispatch({ type: Types.UPDATE_EXTERNAL_IDS, payload: response.data })
      toastr.success('Update External Ids', 'Updated External Ids successfully!')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', 'Could Not change ECPID and ECLID ', {
          className: css({ background: 'red !important' })
        })
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const fetchPortalHistory = portal_id => async dispatch => {
  await branditRails.get(`/portals/${portal_id}/csv_import_logs`).then(function (response) {
    return new Promise((resolve, reject) => {
      dispatch({ type: Types.UPLOAD_HISTORY, payload: response.data })
      resolve()
    })
  })
}

export const fetchProductLocations = id => async dispatch => {
  await branditRails.get(`/products/${id}/locations`).then(function (response) {
    dispatch({
      type: Types.FETCH_PRODUCT_LOCATIONS,
      payload: response.data,
      id
    })
  })
}

export const fetchProductPortals = id => async dispatch => {
  await branditRails.get(`/products/${id}/portals`).then(function (response) {
    dispatch({
      type: Types.FETCH_PRODUCT_PORTALS,
      payload: response.data,
      id
    })
  })
}

export const fetchProductClients = id => async dispatch => {
  await branditRails.get(`/products/${id}/clients`).then(function (response) {
    dispatch({
      type: Types.FETCH_PRODUCT_CLIENTS,
      payload: response.data,
      id
    })
  })
}

// CONTENT BLOCKS
export const newPageContent = (formValues, id) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post(`/pages/${id}/new_content`, formValues)
    .then(function (response) {
      dispatch({
        type: Types.CREATE_PAGE_CONTENT,
        payload: response.data,
        id
      })
      toastr.success('Create Page Content', 'Content Block Created')
    })
    .catch(function (error) {
      if (error.response.status === 422) {
        toastr.error('Error', error.response.data, {
          className: css({ background: 'red !important' }),
          timeOut: 7000
        })
      } else {
        toastr.error('Error', 'Could not create content block', {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const updateContentBlock = (formValues, id) => async dispatch => {
  await branditRails
    .patch(`/page_content_blocks/${id}`, formValues)
    .then(function (response) {
      dispatch({ type: Types.UPDATE_CONTENT_BLOCK, payload: response.data })
      toastr.success('Update Content Block', 'Content block updated successfully!')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', `Could not update content block: ${error}`, {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const deleteContentBlock = id => async dispatch => {
  await branditRails
    .delete(`/page_content_blocks/${id}`)
    .then(function (response) {
      dispatch({ type: Types.DELETE_CONTENT_BLOCK, payload: id })
      toastr.success('Delete Content Block', 'Content Block deleted successfully!')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', 'Cannot delete Content Block', {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const createNewPageFaqItem = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post(`/page_content_blocks/${id}/new_faq_item`, formValues)
    .then(function (response) {
      dispatch({
        type: Types.CREATE_FAQ_ITEMS,
        payload: response.data,
        id
      })
      toastr.success('Create Page Content', 'Content Block Created')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', `Could not create content block: ${error}`, {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const deleteContentItem = (itemId, itemFullModelName) => async dispatch => {
  await branditRails
    .delete(`/page_content_items/${itemId}?item_full_model_name=${itemFullModelName}`)
    .then(function (response) {
      dispatch({ type: 'DELETE_CONTENT_ITEM', payload: id })
      toastr.success('Delete Item', 'Item deleted successfully!')
      history.back()
    })
    .catch(function (error) {
      if (error.response && error.response.status == 422) {
        toastr.error('Error', 'Cannot delete item', {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const updateFaqItem = (itemId, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .patch(`/page_content_blocks/${itemId}/edit_faq_item`, formValues)
    .then(function (response) {
      toastr.success('Update Content Block Item', 'Content block item updated successfully!')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', `Could not update content block item: ${error}`, {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const createNewPageSliderItem = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post(`/page_content_blocks/${id}/new_slider_item`, formValues)
    .then(function (response) {
      dispatch({
        type: Types.CREATE_SLIDER_ITEMS,
        payload: response.data,
        id
      })
      toastr.success('Create Page Content', 'Content Block Created')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', `Could not create content block: ${error}`, {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const updatePageSliderItem = (sliderItemId, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  return await branditRails
    .patch(`/page_content_blocks/${sliderItemId}/edit_slider_item`, formValues)
    .then(function (response) {
      toastr.success('Update Content Block Item', 'Content block item updated successfully!')
      // dispatch({ type: Types.UPDATE_CONTENT_BLOCK, payload: response.data })
      // dispatch({ type: 'UPDATE_CONTENT_BLOCK_ITEM', payload: response.data })
      return response.data
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', `Could not update content block item: ${error}`, {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const createNewPageLinkColItem = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post(`/page_content_blocks/${id}/new_link_col_item`, formValues)
    .then(function (response) {
      dispatch({
        type: Types.CREATE_LINK_COL_ITEMS,
        payload: response.data,
        id
      })
      toastr.success('Create Page Content', 'Content Block Created')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', `Could not create content block: ${error}`, {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const updatePageLinkColItem = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .patch(`/page_content_blocks/${id}/edit_link_col_item`, formValues)
    .then(function (response) {
      toastr.success('Update Content Block Item', 'Content block item updated successfully!')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', `Could not update content block item: ${error}`, {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const createNewPageFeatureItem = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post(`/page_content_blocks/${id}/new_feature_item`, formValues)
    .then(function (response) {
      dispatch({
        type: Types.CREATE_FEATURE_ITEMS,
        payload: response.data,
        id
      })
      toastr.success('Create Page Content', 'Content Block Created')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', `Could not create content block: ${error}`, {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const updatePageFeatureItem = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .patch(`/page_content_blocks/${id}/edit_feature_item`, formValues)
    .then(function (response) {
      toastr.success('Update Content Block Item', 'Content block item updated successfully!')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', `Could not update content block item: ${error}`, {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const createNewPageProsliderItem = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post(`/page_content_blocks/${id}/new_proslider_item`, formValues)
    .then(function (response) {
      dispatch({
        type: Types.CREATE_PROSLIDER_ITEMS,
        payload: response.data,
        id
      })
      toastr.success('Create Page Content', 'Content Block Created')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', `Could not create content block: ${error}`, {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const updatePageProsliderItem = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .patch(`/page_content_blocks/${id}/edit_proslider_item`, formValues)
    .then(function (response) {
      toastr.success('Update Content Block Item', 'Content block item updated successfully!')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', `Could not update content block item: ${error}`, {
          className: css({ background: 'red !important' })
        })
      }
    })
}

// ORDERS
export const fetchOrderCount = async () => {
  return await branditRails.get('/order_count')
}
export const fetchSubOrderCount = async () => {
  return await branditRails.get('/sub_order_count')
}

export const fetchOrders = () => async dispatch => {
  await branditRails.get(`/orders`).then(function (response) {
    dispatch({ type: Types.FETCH_ORDERS, payload: response.data })
  })
}

export const fetchOrdersGrid = queryParams => async dispatch => {
  const params = setParams(queryParams)
  return await branditRails.get(`/orders/index_grid/${params}`).then(function (response) {
    const totalCount = response.headers['x-total-count']
    return Promise.resolve({
      data: response.data,
      count: parseInt(totalCount)
    })
    // dispatch({ type: Types.FETCH_ORDERS, payload: response.data });
  })
}

export const fetchSubOrderFulfilmentView = id => async dispatch => {
  if (id) {
    await branditRails.get(`/sub_orders/${id}`).then(response => {
      const subOrder = response.data
      dispatch({ type: 'FETCH_SUB_ORDER_FOR_FULFILMENT', payload: subOrder })
      if (subOrder && subOrder.warehouse_id) {
        branditRails.get(`/warehouses/${subOrder.warehouse_id}?sub_order_id=${subOrder.id}`).then(resp => {
          dispatch({ type: Types.FETCH_WAREHOUSE, payload: resp.data })
        })
      }
    })
  } else {
    console.error(`Invalid SubOrder ID: ${id}`)
  }
}

export const clearSubOrderFulfilmentView = id => async dispatch => {
  dispatch({ type: 'CLEAR_FULFILMENT_ACTIVE_SUB_ORDER' })
  fetchSubOrderFulfilmentView(id)
}

export const fetchOrder = id => async dispatch => {
  await branditRails.get(`/orders/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_ORDER, payload: response.data })
  })
}

export const fetchPendingOrders = () => async dispatch => {
  await branditRails.get(`/orders/orders_for_approval`).then(function (response) {
    dispatch({ type: Types.FETCH_PENDING_ORDERS, payload: response.data })
  })
}

export const editOrder = (formValues, id) => async dispatch => {
  await branditRails
    .patch(`/orders/${id}`, formValues)
    .then(function (response) {
      dispatch({ type: Types.UPDATE_ORDER, payload: response.data })
      toastr.success('Update Order', 'Order updated successfully!')
    })
    .catch(function (error) {
      if (error.response.status == 421) {
        toastr.error(error, {
          title: 'Error - Couuld not update Order!',
          className: css({
            background: 'red !important',
            color: 'white !important'
          })
        })
      } else if (error.response.status == 423) {
        toastr.error(error.response.data.message, {
          timeOut: 30000,
          attention: true,
          closeOnShadowClick: true,
          className: css({
            background: 'red !important',
            color: 'white !important'
          })
        })
      } else if (error.response.status == 424) {
        toastr.error('Error', error.response.data.message, {
          timeOut: 30000,
          attention: true,
          closeOnShadowClick: true,
          className: css({
            background: 'red !important',
            color: 'white !important'
          })
        })
      }
    })
}

export const editOrderApproval = (formValues, id) => async dispatch => {
  await branditRails
    .patch(`/orders/${id}/approval`, formValues)
    .then(function (response) {
      dispatch({ type: Types.UPDATE_ORDER, payload: response.data })
      toastr.success('Update Order', 'Order updated Successfully!')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', `Could not update Order: ${error}`, {
          classname: css({ background: 'red !important' })
        })
      }
    })
}

export const cancelOrder = id => async dispatch => {
  await branditRails.get(`/orders/${id}/cancel_order`).then(function (response) {
    dispatch({ type: Types.CANCEL_ORDER, payload: response.data })
  })
}

export const cancelSubOrder = (formValues, id) => async dispatch => {
  await branditRails.post(`/sub_orders/${id}/cancel`, formValues).then(response => {
    dispatch({ type: Types.UPDATE_SUB_ORDER, payload: response.data })
    toastr.success('Sub Order Cancelled', 'Sub Order successfully cancelled.')
  })
}

export const editSubOrder = (formValues, id) => async dispatch => {
  await branditRails
    .patch(`/sub_orders/${id}`, formValues)
    .then(function (response) {
      dispatch({ type: Types.UPDATE_SUB_ORDER, payload: response.data })
      toastr.success('Update Order', 'Sub Order updated successfully!')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', `Could not update Sub Order: ${error}`, {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const splitOrder = formValues => async dispatch => {
  await branditRails
    .post(`/orders/split_order`, formValues)
    .then(response => {
      dispatch({ type: Types.SPLIT_ORDER, payload: response.data })
      branditRails.get(`/orders/${response.data.main_order.order.id}/splitted_sub_orders`).then(response => {
        dispatch({
          type: Types.FETCH_SPLITTED_SUB_ORDERS,
          payload: response.data
        })
      })
      toastr.success('Split Order', 'Order splitted successfully!')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', `Could not split Order: ${error}`, {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const filterSubOrders = sub_orders => async dispatch => {
  dispatch({ type: Types.FETCH_SPLITTED_SUB_ORDERS, payload: sub_orders })
}

export const fetchSplittedSubOrders = id => async dispatch => {
  await branditRails.get(`/orders/${id}/splitted_sub_orders`).then(function (response) {
    dispatch({
      type: Types.FETCH_SPLITTED_SUB_ORDERS,
      payload: response.data
    })
  })
}

export const deleteAllSubOrders = id => async dispatch => {
  await branditRails.delete(`/orders/${id}/delete_all_sub_orders`).then(function (response) {
    dispatch({ type: Types.FETCH_SPLITTED_SUB_ORDERS, payload: '' })
  })
}

// MENUS

export const fetchMenuItem = id => async dispatch => {
  await branditRails.get(`/menu_items/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_MENU_ITEM, payload: response.data })
  })
}

export const fetchMenuItems = portal_id => async dispatch => {
  await branditRails.get(`/portal/${portal_id}/menu_items`).then(function (response) {
    dispatch({ type: Types.FETCH_MENU_ITEMS, payload: response.data })
  })
}

export const createMenuItem = (formValues, portal_id) => async dispatch => {
  await branditRails.post(`/portal/${portal_id}/menu_items`, formValues).then(function (response) {
    dispatch({ type: Types.CREATE_MENU_ITEM, payload: response.data })
    toastr.success('Create Menu item', 'Menu item created successfully!', {
      className: css({ background: 'green !important' })
    })
  })
}

export const editMenuItem = (id, formValues) => async dispatch => {
  await branditRails.patch(`/menu_items/${id}`, formValues).then(function (response) {
    dispatch({ type: Types.UPDATE_MENU_ITEM, payload: response.data })
    toastr.success('Update Menu Item', 'Menu Item updated successfully!')
  })
}

export const deleteMenuItem = id => async dispatch => {
  await branditRails.delete(`/menu_items/${id}`).then(function (response) {
    toastr.success('Delete Menu Item', 'Menu Item deleted successfully!')
    dispatch({ type: Types.DELETE_MENU_ITEM, payload: id })
  })
}

export const bulkUpdateMenuItems = menuItemValues => async dispatch => {
  await branditRails.patch('/menu_items_bulk_update', menuItemValues).then(function (response) {
    return new Promise((resolve, reject) => {
      dispatch({
        type: Types.BULK_UPDATE_MENU_ITEMS,
        payload: response.data
      })
      resolve()
    })
  })
}

// AUTHORIZE.NET
export const fetchCardPayments = () => async dispatch => {
  await branditRails
    .get(`/transactions`, {
      params: { request_type: 'get_unsettled_transaction_list' }
    })
    .then(function (response) {
      dispatch({ type: Types.FETCH_CARD_PAYMENTS, payload: response.data })
    })
}

export const checkSystemSettings = portalCode => async dispatch => {
  const keys = [`AUTHORIZENET_TRANSACTION_KEY_${portalCode}`, `AUTHORIZENET_API_LOGIN_ID_${portalCode}`]
  await branditRails
    .get('/system_settings/check_keys', { params: { keys } })
    .then(function (response) {
      dispatch({ type: Types.CHECK_SYSTEM_SETTINGS, payload: response.data.exist })
    })
    .catch(function (error) {
      dispatch({ type: Types.CHECK_SYSTEM_SETTINGS, payload: false })
    })
}

//IMAGES
export const fetchImage = id => async dispatch => {
  await branditRails.get(`/images/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_IMAGE, payload: response.data })
  })
}

export const deleteChildImage = id => async dispatch => {
  await branditRails
    .delete(`/images/${id}`)
    .then(function (response) {
      dispatch({ type: Types.DELETE_CHILD_IMAGE, payload: id })
      toastr.success('Delete Image', 'Image deleted successfully!')
      history.back()
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', 'Cannot delete Image', {
          className: css({ background: 'red !important' })
        })
      }
    })
}

//**************** */
//*   Bundles      */
//**************** */

export const addBundle = formData => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post(`/bundles`, formData)
    .then(function (response) {
      dispatch({ type: Types.ADD_BUNDLE, payload: response.data })
    })

    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
        toastr.error('Error', 'Bundle name already exists', {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const fetchBundles = () => async dispatch => {
  await branditRails.get('/bundles').then(function (response) {
    dispatch({ type: Types.FETCH_BUNDLES, payload: response.data })
  })
}

export const fetchBundle = id => async dispatch => {
  const response = await branditRails.get(`/bundles/${id}`)
  dispatch({ type: Types.FETCH_BUNDLE, payload: response.data })
}

export const addBundleGroup = (id, formData, iter) => async dispatch => {
  dispatch({ type: Types.CLEAR_BUNDLE_GROUP_PRODUCTS, payload: '' })
  await branditRails.post(`/bundles/${id}/bundle_groups`, formData).then(function (response) {
    dispatch({
      type: Types.ADD_BUNDLE_GROUP,
      payload: response.data,
      iter
    })
  })
}

export const addBundleGroupProducts = (id, bundleGroupId, formData) => async dispatch => {
  await branditRails
    .post(`/bundles/${id}/bundle_groups/${bundleGroupId}/bundle_group_products`, formData)
    .then(function (response) {
      dispatch({
        type: Types.FETCH_BUNDLE_GROUP_PRODUCTS,
        payload: response.data
      })
    })
}

export const fetchBundleGroupProducts = (bundleId, bundleGroupId) => async dispatch => {
  dispatch({ type: Types.CLEAR_BUNDLE_GROUP_PRODUCTS, payload: '' })
  await branditRails
    .get(`/bundles/${bundleId}/bundle_groups/${bundleGroupId}/bundle_group_products`)
    .then(function (response) {
      dispatch({
        type: Types.FETCH_BUNDLE_GROUP_PRODUCTS,
        payload: response.data
      })
    })
}

export const updateBundleProduct = (bundle_product_id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .put(`/bundles/0/bundle_groups/0/bundle_group_products/${bundle_product_id}`, formValues)
    .then(function (response) {
      dispatch({
        type: Types.UPDATE_BUNDLE_GROUP_PRODUCT,
        payload: response.data
      })
      toastr.success('Update Bundle Product ', 'Bundle Product updated successfully!')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const editBundle =
  (id, formValues, applyHistory = true, showToaster = true) =>
  async dispatch => {
    const response = await branditRails.patch(`/bundles/${id}`, formValues)
    dispatch({ type: Types.EDIT_BUNDLE, payload: response.data })
    if (showToaster) {
      toastr.success('Bundle update', 'Bundle updated successfully!')
    }
    if (applyHistory) {
      history.back()
    }
  }

export const fetchAssociatedBundles = (productId, productSku) => async dispatch => {
  const response = await branditRails.get(`/associated_bundles/${productId}/${productSku}`)
  dispatch({ type: Types.FETCH_ASSOC_BUNDLES, payload: response.data })
  return response.data
}

/* ARTWORK ACTIONS */
export const fetchArtworks = params => async dispatch => {
  const request = { params }
  const response = await branditRails.get('/artworks', request)
  dispatch({ type: Types.FETCH_ARTWORKS, payload: response.data })
}

export const fetchArtwork = id => async dispatch => {
  const response = await branditRails.get(`/artworks/${id}`)
  dispatch({ type: Types.FETCH_ARTWORK, payload: response.data })
}

export const cloneArtworkFields = (sourceId, destId) => async dispatch => {
  const response = await branditRails.get(`/clone_artwork_fields/${sourceId}/${destId}`)
  dispatch({ type: 'FETCH_ARTWORK', payload: response.data })
}

export const createArtwork = formData => async dispatch => {
  await branditRails.post(`/artworks`, formData).then(function (response) {
    toastr.success('Create Artwork', 'Artwork Created successfully!')
    history.back()
  })
}

export const editArtwork = (id, formValues) => async dispatch => {
  await branditRails.patch(`/artworks/${id}`, formValues).then(function (response) {
    dispatch({ type: Types.EDIT_ARTWORK, payload: response.data })
    toastr.success('Update Artwork', 'Artwork Updated successfully!')
  })
}

export const previewArtwork = id => async dispatch => {
  const response = await branditRails.get(`/preview_artwork/${id}`)
  dispatch({ type: Types.PREVIEW_ARTWORK, payload: response.data })
}

export const deleteArtwork = id => async dispatch => {
  await branditRails
    .delete(`/artworks/${id}`)
    .then(function (response) {
      dispatch({ type: Types.DELETE_ARTWORK, payload: id })
      toastr.success('Delete Artwork', 'Artwork deleted successfully!')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', 'Cannot delete Artwork', {
          className: css({ background: 'red !important' })
        })
      }
    })
}

/* ARTWORK FAMILY ACTIONS */
export const fetchArtworkFamilies = () => async dispatch => {
  await branditRails.get(`/artwork_families`).then(function (response) {
    dispatch({ type: Types.FETCH_ARTWORK_FAMILIES, payload: response.data })
  })
}

export const createArtworkFamily = formData => async dispatch => {
  await branditRails.post(`/artwork_families`, formData).then(function (response) {
    toastr.success('Create Artwork Family', 'Artwork family created successfully!')
    history.back()
  })
}

export const fetchPromotion = id => async dispatch => {
  await branditRails.get(`/promotions/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_PROMOTION, payload: response.data })
  })
}

export const createPromotion = formValues => async (dispatch, getState) => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post('/promotions', formValues)
    .then(function (response) {
      dispatch({ type: Types.CREATE_PROMOTION, payload: response.data })
      toastr.success('Create Promotion', 'Promotion created successfully!', {
        className: css({ background: 'green !important' })
      })
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const fetchPromotionCode = id => async dispatch => {
  await branditRails.get(`/promotion_codes/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_PROMOTION_CODE, payload: response.data })
  })
}

export const editPromotionCode = (id, formValues, promotion_id) => async dispatch => {
  await branditRails.patch(`/promotion_codes/${id}`, formValues).then(function (response) {
    toastr.success('Update Promotion Code', 'Promotion Code updated successfully!')
  })
}

export const createPromotionCodes = (formValues, id) => async (dispatch, getState) => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post(`/promotions/${id}/generate_codes`, formValues)
    .then(function (response) {
      dispatch({
        type: Types.CREATE_PROMOTION_CODES,
        payload: response.data
      })
      toastr.success('Generate Promotion Codes', 'Promotion codes generated successfully!', {
        className: css({ background: 'green !important' })
      })
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const deletePromotion = id => async dispatch => {
  await branditRails.delete(`/promotions/${id}`).then(function (response) {
    dispatch({ type: Types.DELETE_PROMOTION, payload: id })
    history.back()
  })
}

export const fetchPromotionRule = id => async dispatch => {
  await branditRails.get(`/promotion_rules/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_PROMOTION_RULE, payload: response.data })
  })
}

export const createPromotionRule = (formValues, promotion_id) => async (dispatch, getState) => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post('/promotion_rules', formValues)
    .then(function (response) {
      dispatch({ type: Types.CREATE_PROMOTION_RULE, payload: response.data })
      toastr.success('Create Promotion Rule', 'Promotion Rule created successfully!', {
        className: css({ background: 'green !important' })
      })
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const deletePromotionRule = id => async dispatch => {
  await branditRails.delete(`/promotion_rules/${id}`).then(function (response) {
    // dispatch({ type: Types.DELETE_PROMOTION, payload: id });
    history.back()
  })
}

export const fetchPromotionValidation = id => async dispatch => {
  await branditRails.get(`/promotion_validations/${id}`).then(function (response) {
    dispatch({
      type: Types.FETCH_PROMOTION_VALIDATION,
      payload: response.data
    })
  })
}

export const createPromotionValidation = (formValues, promotion_id) => async (dispatch, getState) => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post('/promotion_validations', formValues)
    .then(function (response) {
      dispatch({
        type: Types.CREATE_PROMOTION_VALIDATION,
        payload: response.data
      })
      toastr.success('Create Promotion Validation', 'Promotion Validation created successfully!', {
        className: css({ background: 'green !important' })
      })
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const deletePromotionValidation = id => async dispatch => {
  await branditRails.delete(`/promotion_validations/${id}`).then(function (response) {
    // dispatch({ type: Types.DELETE_PROMOTION, payload: id });
    history.back()
  })
}

export const createPromotionCode = (formValues, promotion_id) => async (dispatch, getState) => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post('/promotion_codes', formValues)
    .then(function (response) {
      dispatch({ type: Types.CREATE_PROMOTION_CODE, payload: response.data })
      toastr.success('Create Promotion Code', 'Promotion Code created successfully!')
      history.push(`/show_promotion/${promotion_id}`)
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const deletePromotionCode = id => async dispatch => {
  await branditRails.delete(`/promotion_codes/${id}`).then(function (response) {
    history.back()
  })
}

/* PRODUCT ARTWORK ACTIONS */
export const createProductArtwork = formData => async dispatch => {
  await branditRails.post(`/product_artworks`, formData).then(function (response) {
    toastr.success('Add Artwork', 'Artwork added successfully!')
  })
}

// Sub order tags

export const fetchTags = () => async dispatch => {
  const response = await branditRails.get(`/tags`)
  dispatch({ type: Types.FETCH_TAGS, payload: response.data })
}

export const addTagsToSubOrder = (id, formData) => async dispatch => {
  await branditRails.post(`/sub_orders/${id}/add_tags`, formData).then(function (response) {
    // dispatch({ type: Types.ADD_SUB_ORDER_TAGS, payload: response.data })
  })
}

export const bulkAddTagsToSubOrders = formData => async dispatch => {
  await branditRails.post(`/sub_orders/bulk_add_tags`, formData).then(function (response) {
    dispatch({ type: 'ADD_SUB_ORDER_TAGS', payload: response.data })
  })
}

export const createSubOrderTag = (id, formData) => async dispatch => {
  return await branditRailsSkipInterceptors
    .post(`/sub_orders/${id}/new_tag`, formData)
    .then(response => {
      toastr.success('Create Tag', 'Tag created successfully!')
      dispatch({ type: 'CREATE_SUB_ORDER_TAG', payload: response.data })
      return response
    })
    .catch(error => {
      toastr.error('Error', `Could not create Tag: ${error?.response?.data}`, {
        className: css({ background: 'red !important' }),
        // long
        timeOut: 7000
      })
      return error.response
    })
}

export const removeSubOrderTag = (id, tagId) => async dispatch => {
  await branditRails.delete(`sub_orders/${id}/tag/${tagId}`).then(response => {
    // dispatch({ type: Types.FETCH_TAGS, payload: response.data })
  })
}

export const mergeSubOrders = formData => async dispatch => {
  await branditRails.post(`/sub_orders/merge`, formData).then(function (response) {
    dispatch({ type: Types.MERGE_SUB_ORDERS, payload: response.data })
  })
}

export const fetchSmartAddress = id => async dispatch => {
  await branditRails.get(`/smart_address/${id}`).then(function (response) {
    dispatch({
      type: Types.FETCH_SMART_ADDRESS,
      payload: response.data,
      id
    })
  })
}

// ------ REFUNDS -------
export const createRefund = formValues => async dispatch => {
  await branditRails.post('/refund_orders', formValues).then(function (response) {
    dispatch({ type: Types.CREATE_REFUND, payload: response.data })
    toastr.success('Create Refund', 'Refund created successfully!')
  })
}

export const fetchOrderRefunds = order_id => async dispatch => {
  await branditRails.get(`/refunds?order_id=${order_id}`).then(function (response) {
    dispatch({ type: Types.FETCH_REFUND, payload: response.data })
  })
}

export const fetchRefundsSummary = id => async dispatch => {
  await branditRails.get(`/orders/${id}/refund_summary`).then(function (response) {
    dispatch({
      type: Types.FETCH_REFUND_SUMMARY,
      payload: response.data.order_refunds_summary
    })
  })
}

export const fetchOrderAfterRefunds = id => async dispatch => {
  await branditRails.get(`/orders/${id}/order_after_refunds`).then(function (response) {
    dispatch({
      type: Types.FETCH_ORDER_AFTER_REFUNDS,
      payload: response.data
    })
  })
}

export const fetchRefundStatuses = id => async dispatch => {
  await branditRails.get(`/refunds/refund_statuses?order_id=${id}`).then(function (response) {
    dispatch({
      type: Types.FETCH_ORDER_REFUND_STATUSES,
      payload: response.data
    })
  })
}

// ------ end REFUNDS -------

// Shipping
export const fetchShippingDimensionPresets = () => async dispatch => {
  await branditRails.get(`/shipping_dimension_presets`).then(response => {
    dispatch({
      type: 'FETCH_SHIPPING_DIMENSION_PRESETS',
      payload: response.data
    })
  })
}

export const createShippingDimensionPreset = data => async dispatch => {
  return await branditRails
    .post(`/shipping_dimension_presets`, data)
    .then(response => {
      toastr.success('Shipping Dimension Preset', 'Shipping Preset Created Successfully!')
      dispatch({
        type: 'CREATE_SHIPPING_DIMENSION_PRESETS',
        payload: response.data
      })
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

export const editShippingDimensionPreset = data => async dispatch => {
  return await branditRails
    .patch(`/shipping_dimension_presets/${data.id}`, data)
    .then(response => {
      toastr.success('Shipping Dimension Preset', 'Shipping Preset Edited Successfully!')
      dispatch({
        type: 'EDIT_SHIPPING_DIMENSION_PRESETS',
        payload: response.data
      })
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

export const deleteShippingDimensionPreset = data => async dispatch => {
  return await branditRails
    .delete(`/shipping_dimension_presets/${data.id}`, data)
    .then(response => {
      toastr.success('Shipping Dimension Preset', 'Shipping Preset Was Deleted Successfully!')
      dispatch({ type: 'DELETE_SHIPPING_DIMENSION_PRESETS', payload: data })
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

export const fetchCarriers = () => async dispatch => {
  await branditRails.get(`/sub_orders/get_carriers`).then(function (response) {
    dispatch({ type: Types.FETCH_CARRIERS, payload: response.data })
  })
}

export const fetchCarrierIds = () => async dispatch => {
  await branditRails.get(`/sub_orders/get_carriers_ids`).then(function (response) {
    dispatch({ type: Types.FETCH_CARRIERS_IDS, payload: response.data })
  })
}

export const fetchCarrierMethods = carrier => async dispatch => {
  await branditRails.post(`/sub_orders/get_carrier_methods`, carrier).then(function (response) {
    dispatch({ type: Types.FETCH_CARRIER_METHODS, payload: response.data })
  })
}

export const clearCarrierRates = () => async dispatch => {
  // also clear form selection when clearing carrier rates
  change('FulfilmentDropshipDialogue', 'rates', {})
  dispatch({ type: 'CLEAR_CARRIER_RATES' })
  dispatch({ type: 'CLEAR_CARRIER_RATES_MULTI_BOX' })
}

export const getShippingLabel = (id, formData) => async dispatch => {
  return await branditRails
    .post(`/sub_orders/${id}/get_label`, formData)
    .then(function (response) {
      toastr.success('Shipping Label', 'Shipping Label created successfully!')
      const subOrderLabel = response.data.sub_order_label
      dispatch({ type: Types.FETCH_SHIPPING_LABEL, payload: subOrderLabel })
      return subOrderLabel
    })
    .catch(function (error) {
      if (error.response.status == 406) {
        const shipengine_errors = error.response.data
        shipengine_errors.forEach(error => {
          alert(error.message)
          console.error(error.message)
        })
        dispatch({ type: Types.FETCH_SHIPPING_LABEL, payload: { id: -1 } })
      }
    })
}

export const createShippingLabelFromRate = (subOrderId, formData) => async dispatch => {
  return await branditRails
    .post(`/sub_orders/${subOrderId}/create_label_from_rate`, formData)
    .then(function (response) {
      toastr.success('Shipping Label', 'Shipping Label created successfully!')
      const subOrderLabel = response.data.sub_order_label
      dispatch({ type: Types.FETCH_SHIPPING_LABEL, payload: subOrderLabel })
      return subOrderLabel
    })
    .catch(function (error) {
      if (error.response.status == 406) {
        const shipengine_errors = error.response.data
        shipengine_errors.forEach(error => {
          alert(error.message)
          console.error(error.message)
        })
        dispatch({ type: Types.FETCH_SHIPPING_LABEL, payload: { id: -1 } })
      }
    })
}

// export const getMultiBoxShippingLabel = (id, formData) => async (dispatch) => {
//   JSON.stringify(formData)
//   await branditRails
//     .post(`/sub_orders/${id}/get_label?multibox=true`, formData)
//     .then((response) => {
//       dispatch({ type: Types.FETCH_SHIPPING_LABEL, payload: response.data })
//     })
//     .catch((error) => {})
// }

export const setActiveShippingLabel = (id, label_id) => async dispatch => {
  await branditRails.get(`/sub_orders/${id}/${label_id}/set_active_label`)
  // .then(function (response) {
  //   dispatch({ type: Types.SET_ACTIVE_LABEL, payload: response.data });
  // });
}

export const fetchCarrierRates = (id, carrierData, carrierValue) => async dispatch => {
  return await branditRails
    .post(`/sub_orders/${id}/get_rates`, carrierData)
    .then(function (response) {
      dispatch({
        type: Types.FETCH_CARRIER_RATES,
        payload: response.data,
        carrierValue
      })
      return response
    })
    .catch(function (error) {
      if (error.response.status === 422) {
        error.response.data.forEach(message => {
          return toastr.error('Error', message, {
            className: css({ background: 'red !important ' })
          })
        })
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
      throw error
    })
}

export const fetchCarrierRatesMultiBox = (id, formData) => async dispatch => {
  return await branditRails
    .post(`/sub_orders/${id}/get_multibox_rates`, formData)
    .then(function (response) {
      dispatch({
        type: Types.FETCH_CARRIER_RATES_MULTI_BOX,
        payload: response.data
      })
      return response
    })
    .catch(function (error) {
      if (error.response.status === 422) {
        error.response.data.forEach(message => {
          return toastr.error('Error', message, {
            className: css({ background: 'red !important ' })
          })
        })
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
      throw error
    })
}

export const fetchThirdPartyBillings = () => async dispatch => {
  await branditRails.get('/third_party_billings').then(function (response) {
    dispatch({
      type: Types.FETCH_THIRD_PARTY_BILLINGS,
      payload: response.data
    })
  })
}

export const createThirdPartyBilling = data => async dispatch => {
  return await branditRails
    .post('/third_party_billings', data)
    .then(function (response) {
      dispatch({
        type: Types.CREATE_THIRD_PARTY_BILLING,
        payload: response.data
      })
      toastr.success('New Shipping Account', 'Shipping Account Created Successfully')
      return response
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        toastr.error('Error', 'Nickname Already In Use', {
          className: css({ background: 'red !important' })
        })
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
      return error
    })
}

//PURCHASE ORDER
export const createPurchaseOrder = formData => async dispatch => {
  const response = await branditRails.post(`/purchase_orders`, formData)
  dispatch({ type: Types.CREATE_PURCHASE_ORDER, payload: response.data })
  return response.data
}
export const editPurchaseOrder = (id, formValues) => async dispatch => {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json'
    }
  }

  try {
    const response = await branditRails.patch(`purchase_orders/${id}`, formValues, requestOptions)
    dispatch({ type: Types.EDIT_PURCHASE_ORDER, payload: response.data })
    toastr.success('Update Purchase Order', 'Purchase Order Updated Successfully!')
    return true
  } catch (error) {
    if (error.response && error.response.status === 422) {
      dispatch({
        type: Types.CREATE_SESSION_ERROR,
        payload: error.response.data
      })
    }
    return false
  }
}

export const duplicatePurchaseOrder = (id, formData) => async () => {
  try {
    const response = await branditRails.post(`/purchase_orders/${id}/duplicate`, JSON.stringify(formData), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return response.data
  } catch (error) {
    return false
  }
}

export const updatePurchaseOrderItems = (id, formValues) => async dispatch => {
  await branditRails.patch(`purchase_orders/purchase_order_edit/${id}/items`, formValues).then(response => {
    dispatch({ type: Types.FETCH_PURCHASE_ORDER, payload: response.data })
    toastr.success('Update Purchase Order Items', 'Purchase Order Items Updated Successfully!')
  })
}

// export const updatePurchaseOrderItems = (jsonArgs) => async dispatch => {
//     await branditRails.patch(`/purchase_orders_items/${jsonArgs}`).then(response => {
//     dispatch({ type: Types.FETCH_PURCHASE_ORDER, payload: response.data })
//   })
// }

export const fetchPurchaseOrder = id => async dispatch => {
  await branditRails.get(`/purchase_orders/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_PURCHASE_ORDER, payload: response.data })
  })
}

export const fetchPurchaseOrdersCountIncoming = async () => {
  return await branditRails.get(`/mf/grid/purchase_orders/count_incoming`)
}

export const fetchPurchaseOrdersCountPastDue = async () => {
  return await branditRails.get(`/mf/grid/purchase_orders/count_past_due`)
}

export const fetchPurchaseOrdersCountUnsent = async () => {
  return await branditRails.get(`/mf/grid/purchase_orders/count_unsent`)
}

export const fetchPurchaseOrderItems = purchase_order_id => async dispatch => {
  const response = await branditRails.get(`/purchase_order_items?purchase_order_id=${purchase_order_id}`)

  const updatedItems = response.data.map(item => {
    return {
      ...item,
      total_cost_cents: item.total_cost_cents * 100,
      unit_cost_cents: item.unit_cost_cents * 100
    }
  })

  dispatch({
    type: Types.FETCH_PURCHASE_ORDER_ITEMS,
    payload: updatedItems
  })
}

export const deletePurchaseOrderItem = id => async dispatch => {
  await branditRails.delete(`/purchase_order_items/${id}`).then(function (response) {
    dispatch({ type: Types.DELETE_PURCHASE_ORDER_ITEM, payload: id })
    toastr.success('Delete Purchase Order Item', 'Item successfully deleted from Purchase Order!')
  })
}

export const updatePurchaseOrderItem = (id, formValues) => {
  branditRails
    .patch(`/update_received/${id}`, formValues)
    .then(function (response) {
      // Uncomment the following line if you need to dispatch an action
      // dispatch({ type: Types.UPDATE_PURCHASE_ORDER_ITEM, payload: response.data });
      toastr.success('Update Purchase Order Item', 'Item successfully updated!')
    })
    .catch(function (error) {
      let errorMessage = 'Error updating item'
      if (error.response && error.response.data && error.response.data.errors) {
        errorMessage = error.response.data.errors
      }
      toastr.error('Error', errorMessage)
    })
}

export const createGoodsReceipt = formData => async dispatch => {
  try {
    const response = await branditRails.post('/goods_receipts', JSON.stringify(formData), {
      headers: {
        'Content-Type': 'application/json'
      }
    })

    dispatch({ type: Types.CREATE_GOODS_RECEIPT, payload: response.data })
    toastr.success('Add Goods Receipt', 'Goods Receipt added successfully!')
  } catch (error) {
    console.error('Error in createGoodsReceipt:', error)
    toastr.error('Error', 'An error occurred while adding Purchase Order.')
  }
}

export const updateGoodsReceipt = (id, data) => async () => {
  try {
    await branditRails.patch(`/goods_receipts/${id}`, JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    toastr.success('Goods Receipt Notes Updated', 'Goods Receipt notes updated successfully!')
  } catch (error) {
    console.error('Error in updateGoodsReceipt:', error)
    toastr.error('Error', 'An error occurred while updating goods receipt')
  }
}

export const fetchGoodsReceiptLogs = id => async dispatch => {
  try {
    const response = await branditRails.get(`/goods_receipts/${id}/logs/notes`)
    dispatch({ type: Types.FETCH_GOODS_RECEIPT_LOGS, payload: { id, logs: response.data } })
  } catch (error) {
    console.error('Error in fetchGoodsReceiptLogs:', error)
    toastr.error('Error', 'An error occurred while fetching goods receipt logs')
  }
}

export const paginateAdvanced =
  (methods, model, page, per_page, sort_by, order, search, clear = false) =>
  async dispatch => {
    let queryString = `/paginate/advanced/${model}?page=${page}&per_page=${per_page}`
    if (sort_by) {
      queryString += `&sort_by=${sort_by}&order=${order}`
    }
    if (search) {
      queryString += `&search=${search}`
    }

    const response = await branditRails.post(queryString, methods)
    if (clear) {
      dispatch({
        type: Types.FETCH_PAGINATED_CLEAR,
        payload: response.data,
        page
      })
    } else {
      dispatch({
        type: Types.FETCH_PAGINATED,
        payload: response.data,
        page
      })
    }
  }

export const fetchPaginatedSubOrders =
  (
    methods = null,
    model = 'SubOrders',
    page = 1,
    per_page = 50,
    sort_by = false,
    order = false,
    search,
    clear = false,
    additionalParams = ''
  ) =>
  async dispatch => {
    let queryString = `/paginate/advanced/${model}?page=${page}&per_page=${per_page}${additionalParams}`
    if (sort_by) {
      queryString += `&sort_by=${sort_by}&order=${order}`
    }
    if (search) {
      queryString += `&search=${search}`
    }
    const response = await branditRails.post(queryString, methods)
    if (clear) {
      dispatch({
        type: Types.FETCH_PAGINATED_CLEAR,
        payload: response.data,
        page
      })
    } else {
      dispatch({
        type: Types.FETCH_PAGINATED,
        payload: response.data,
        page
      })
      return response.data
    }
  }

export const fetchCSVforSubOrders =
  (methods, model, page, per_page, sort_by, order, search, clear = false, additionalParams = '', columns = '') =>
  async () => {
    let queryString = `/paginate/advanced/${model}?page=${page}&per_page=${per_page}${additionalParams}`
    if (sort_by) {
      queryString += `&sort_by=${sort_by}&order=${order}`
    }
    if (search) {
      queryString += `&search=${search}`
    }
    const response = await branditRails.post(queryString, {
      download: 'csv',
      columns: JSON.stringify(columns)
    })
    return response.data
  }

//******************* */
//*   Shipping HQ     */
//******************* */

export const fetchShqShippingGroups = () => async dispatch => {
  await branditRails.get('/shq_shipping_groups').then(response => {
    dispatch({ type: Types.FETCH_SHQ_SHIPPING_GROUPS, payload: response.data })
  })
}

export const fetchProductShqShippingGroups = (product_id, parent, parent_id) => async dispatch => {
  const parent_id_params = `&${parent}=${parent_id}`

  return await branditRails
    .get(`/shq_shipping_groups/product_shipping_groups?product_id=${product_id}${parent_id_params}`)
    .then(response => {
      dispatch({
        type: Types.FETCH_PRODUCT_SHQ_SHIPPING_GROUPS,
        payload: response.data
      })
      return response
    })
}

export const fetchShqOrigins = () => async dispatch => {
  await branditRails.get('/shq_origins').then(response => {
    dispatch({ type: Types.FETCH_SHQ_ORIGINS, payload: response.data })
  })
}

export const fetchProductShqShippingOrigins = (product_id, parent, parent_id) => async dispatch => {
  const parent_id_params = `&${parent}=${parent_id}`
  return await branditRails
    .get(`/shq_origins/product_shipping_origins?product_id=${product_id}${parent_id_params}`)
    .then(response => {
      dispatch({
        type: Types.FETCH_PRODUCT_SHQ_SHIPPING_ORIGINS,
        payload: response.data
      })
      return response
    })
}

export const fetchShqPackingRules = () => async dispatch => {
  await branditRails.get('/shq_packing_rules').then(response => {
    dispatch({ type: Types.FETCH_SHQ_PACKING_RULES, payload: response.data })
  })
}

export const fetchProductShqPackingRule = (product_id, parent, parent_id) => async dispatch => {
  const parent_id_params = `&${parent}=${parent_id}`
  await branditRails
    .get(`/shq_packing_rules/product_packing_rules?product_id=${product_id}${parent_id_params}`)
    .then(response => {
      dispatch({
        type: Types.FETCH_PRODUCT_SHQ_PACKING_RULE,
        payload: response.data
      })
    })
}

export const fetchShqBoxes = () => async dispatch => {
  await branditRails.get('/shq_boxes').then(response => {
    dispatch({ type: Types.FETCH_SHQ_BOXES, payload: response.data })
  })
}

export const fetchProductShqShippingBoxes = (product_id, parent, parent_id) => async dispatch => {
  const parent_id_params = `&${parent}=${parent_id}`
  return await branditRails
    .get(`/shq_boxes/product_shipping_boxes?product_id=${product_id}${parent_id_params}`)
    .then(response => {
      dispatch({
        type: Types.FETCH_PRODUCT_SHQ_SHIPPING_BOXES,
        payload: response.data
      })
      return response
    })
}

export const fetchShipmentDetails = subOrder_id => async dispatch => {
  if (!subOrder_id) return

  await branditRails.get(`/sub_orders/${subOrder_id}/status`).then(response => {
    dispatch({
      type: Types.FETCH_SHIPMENT_STATUS,
      payload: response.data,
      id: subOrder_id
    })
  })
}

export const notifyUser = subOrder_id => async dispatch => {
  await branditRails.get(`/sub_orders/${subOrder_id}/notify_shipped`).then(response => {
    dispatch({ type: Types.NOTIFY_USER, payload: response.data })
    toastr.success('Success', 'Shipment Notifcation Email Sent Successfully!')
  })
}

// generates packing slip and updates sub order
export const generatePackingSlip = subOrder_id => async dispatch => {
  await branditRails.get(`/sub_orders/${subOrder_id}/get_packing_slip`).then(response => {
    dispatch({
      type: 'FETCH_SUB_ORDER_FOR_FULFILMENT',
      payload: response.data
    })
  })
}

export const deleteLabel = (subOrder_id, label_id) => async dispatch => {
  await branditRails.get(`/sub_orders/${subOrder_id}/delete_label?label_id=${label_id}`).then(response => {
    dispatch({ type: Types.DELETE_LABEL, payload: response.data })
  })
}

export const labelManual = (id, formData) => async dispatch => {
  const url = `/sub_orders/${id}/label_manual`
  await branditRails.post(url, formData).then(function (response) {
    toastr.success('Success', 'Manual Label Updated Successfully')
    dispatch({ type: Types.LABEL_MANUAL, payload: response.data })
  })
}

export const validateAddress = async formData => {
  const response = await branditRails.post('/sub_order_addresses/validate', formData)
  return response.data[0]
}

export const dispatchSweetAlert = propsForSweetAlert => async dispatch => {
  dispatch({ type: 'SHOW_SWEETALERT', payload: propsForSweetAlert })
}

export const clearSweetAlert = () => async dispatch => {
  dispatch({ type: 'CLEAR_SWEETALERT' })
}

export const updateSubOrderAddress = (sub_order_id, formData, callback) => async dispatch => {
  return await branditRails.patch(`/sub_order_addresses/${sub_order_id}`, formData).then(function (response) {
    if (response && response.data && response.status === 200) {
      // address update success
      dispatch({
        type: 'FETCH_SUB_ORDER_FOR_FULFILMENT',
        payload: response.data,
        id: sub_order_id
      })
      toastr.success('Success', 'Sub Order Address updated')
      if (callback) {
        callback(response.data)
      }
      return response.data
    } else {
      // api failure
      return false
    }
  })
}

//TIER PRICE//

export const fetchTierPrices = product_list_id => async dispatch => {
  await branditRails.get(`/tier_prices?product_list_id=${product_list_id}`).then(response => {
    dispatch({ type: Types.FETCH_TIER_PRICES, payload: response.data })
  })
}

export const fetchTierPrice = id => async dispatch => {
  await branditRails.post(`/tier_prices/${id}`).then(function (response) {
    dispatch({ type: Types.FETCH_TIER_PRICE, payload: response.data })
  })
}

export const createTierPrice = formData => async dispatch => {
  await branditRails.post(`/tier_prices`, formData).then(function (response) {
    dispatch({ type: Types.CREATE_TIER_PRICE, payload: response.data })
  })
}

export const updateTierPrice = (id, formValues) => async dispatch => {
  await branditRails.patch(`/tier_prices/${id}`, formValues).then(response => {
    dispatch({ type: Types.UPDATE_TIER_PRICE, payload: response.data })
  })
}

export const deleteTierPrice = id => async dispatch => {
  await branditRails.delete(`/tier_prices/${id}`).then(function (response) {
    dispatch({ type: Types.DELETE_TIER_PRICE, payload: id })
  })
}

export const fetchDPData = item_id => async dispatch => {
  return await branditRails
    .get(`/sub_order_items/${item_id}/show_digital_proofing`)
    .then(response => {
      dispatch({
        type: Types.FETCH_DP_DATA,
        payload: response.data,
        id: item_id
      })
      return response.data
    })
    .catch(function (error) {
      if (error.response.status == 404) {
        toastr.error('Proof Not Found', 'Could not find proof for that item.', {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const fetchDPDataForDropshipBatch = dropship_batch_id => async dispatch => {
  return await branditRails
    .get(`/dropship_batch/${dropship_batch_id}/show_digital_proofing`)
    .then(response => {
      dispatch({ type: Types.FETCH_BATCH_DP_DATA, payload: response.data })
      return response.data
    })
    .catch(function (error) {
      if (error.response.status == 404) {
        toastr.error('Proof Not Found', 'Could not find proof for that item.', {
          className: css({ background: 'red !important' })
        })
      }
    })
}

export const generateProof = item_id => async dispatch => {
  await branditRails.get(`/sub_order_items/${item_id}/generate_proof`).then(response => {
    dispatch({
      type: Types.FETCH_DP_DATA,
      payload: response.data,
      id: item_id
    })
  })
}

/** Roles */
// export const fetchRoles = () => async dispatch => {
//   await branditRails.get('/roles').then(response => {
//     dispatch({ type: Types.FETCH_ROLES, payload: response.data })
//   })
// }

export const fetchAllRoles = () => async dispatch => {
  await branditRails.get('/all_roles').then(response => {
    dispatch({ type: 'SET_ROLES', payload: response.data })
  })
}

export const fetchRole = id => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails.get(`/roles/${id}`).then(response => {
    dispatch({ type: Types.FETCH_ROLE, payload: response.data })
  })
}

export const createRole = formValues => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post('/roles', formValues)
    .then(function (response) {
      dispatch({ type: Types.CREATE_ROLE, payload: response.data })
      history.back()
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const editRole = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .patch(`/roles/${id}`, formValues)
    .then(function (response) {
      dispatch({ type: Types.EDIT_ROLE, payload: response.data })
      history.back()
      toastr.success('Role has been edited', 'Role has been edited')
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const deleteRole = id => async dispatch => {
  await branditRails
    .delete(`/roles/${id}`)
    .then(function () {
      dispatch({ type: Types.DELETE_ROLE, payload: id })
      toastr.success('Role Delete', 'Role deleted successfully!')
      history.back()
    })
    .catch(function (error) {
      if (error.response.status == 500) {
        toastr.error('Error', 'You can not delete a role you are currently assigned to', {
          className: css({ background: 'red !important' })
        })
      }
    })
}

// export const updateRolesFlag = (id, value) => async dispatch => {
//   await branditRails.patch(`/role_status/${id}`, value).then(response => {
//     dispatch({ type: Types.FETCH_ROLES, payload: response.data })
//   })
// }

export const createTemplate = formValues => async dispatch => {
  await branditRails.post('/templates', formValues).then(function (response) {
    dispatch({ type: Types.CREATE_TEMPLATE, payload: response.data })
    toastr.success('Create Template', 'Template created successfully!')
  })
}

export const fetchTemplates = () => async dispatch => {
  await branditRails.get('/templates').then(response => {
    dispatch({ type: Types.FETCH_TEMPLATES, payload: response.data })
  })
}

export const fetchActiveTemplates = () => async dispatch => {
  await branditRails.get('/active_templates').then(response => {
    dispatch({ type: Types.FETCH_TEMPLATES, payload: response.data })
  })
}

export const fetchTemplate = id => async dispatch => {
  await branditRails.get(`/templates/${id}`).then(response => {
    dispatch({ type: Types.FETCH_TEMPLATE, payload: response.data })
  })
}

export const updateTemplate = (id, formValues) => async dispatch => {
  await branditRails.patch(`/templates/${id}`, formValues).then(response => {
    dispatch({ type: Types.EDIT_TEMPLATE, payload: response.data })
    toastr.success('Update Template', 'Template has been updated successfully')
  })
}

export const deleteTemplate = id => async dispatch => {
  await branditRails.delete(`/templates/${id}`).then(response => {
    dispatch({ type: Types.DELETE_TEMPLATE, payload: response.data.id })
    toastr.success('Delete Template', 'Template has been successfully deleted')
    history.back()
  })
}

/** Role Permissions */
export const addPermissions = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post(`/roles/${id}/permissions`, formValues)
    .then(function (response) {
      dispatch({ type: Types.ADD_PERMISSIONS, payload: response.data })
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const fetchAdminPermissions = id => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .get(`/admin_permissions/${id}`)
    .then(function (response) {
      sessionStorage.setItem('admin_permissions', response.data)
      dispatch({ type: 'ADMIN_PERMISSIONS', payload: response.data })
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const fetchAdminHaveRolesAssigned = id => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails.get(`/check_role/${id}`).then(response => {
    dispatch({ type: Types.ADMIN_HAS_ROLES, payload: response.data })
  })
}

export const removeProductArtwork = id => async dispatch => {
  await branditRails.delete(`/destroy_product_artwork/${id}`).then(response => {
    dispatch({ type: Types.DESTROY_PRODUCT_ARTWORK, payload: response.data })
  })
}

export const fetchPortalTagSets = id => async dispatch => {
  await branditRails.get(`/portal_tags/tag_sets/${id}`).then(response => {
    dispatch({ type: Types.FETCH_TAG_SETS, payload: response.data, id })
  })
}

/** Role Permissions */
export const createTagSet = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post(`/portal_tags/create_set/${id}`, formValues)
    .then(function (response) {
      dispatch({
        type: Types.CREATE_PORTAL_TAG,
        payload: response.data,
        id
      })
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const deleteTagSet = (set_id, id) => async dispatch => {
  await branditRails.get(`/portal_tags/delete_tag_set/${set_id}`).then(response => {
    //we reuse another type as the api call is set to return the new list without the removed set, this avoids needing an aditional api call after the delete is performed
    dispatch({ type: Types.FETCH_TAG_SETS, payload: response.data, id })
  })
}

/** Role Permissions */
export const editTagSet = (portal_id, id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post(`/portal_tags/edit_set/${id}`, formValues)
    .then(function (response) {
      dispatch({
        type: Types.FETCH_TAG_SETS,
        payload: response.data,
        id: portal_id
      })
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const showTagSet = id => async dispatch => {
  await branditRails.get(`/portal_tags/${id}`).then(response => {
    dispatch({
      type: Types.FETCH_SHOW_TAG_SET,
      payload: response.data,
      id
    })
  })
}

export const bulkUpdatePortalTagOrder = (tag_set_id, menuItemValues) => async dispatch => {
  await branditRails.patch(`/portal_tag_orders/${tag_set_id}`, menuItemValues).then(function (response) {
    return new Promise((resolve, reject) => {
      dispatch({
        type: Types.FETCH_SHOW_TAG_SET,
        payload: response.data,
        id: tag_set_id
      })
      resolve()
    })
  })
}

/** Role Permissions */
export const createPortalTag = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post(`/portal_tags/create_portal_tag/${id}`, formValues)
    .then(function (response) {
      dispatch({
        type: Types.FETCH_SHOW_TAG_SET,
        payload: response.data,
        id
      })
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}
export const deletePortalTag = id => async dispatch => {
  await branditRails.delete(`/portal_tags/delete_portal_tag/${id}`).then(response => {
    dispatch({
      type: Types.FETCH_SHOW_TAG_SET,
      payload: response.data,
      id
    })
  })
}

export const editPortalTag = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post(`/portal_tags/edit_tag/${id}`, formValues)
    .then(function (response) {
      dispatch({
        type: Types.FETCH_SHOW_TAG_SET,
        payload: response.data,
        id
      })
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const showPortalTag = id => async dispatch => {
  await branditRails.get(`/portal_tags/tag/${id}`).then(response => {
    dispatch({ type: Types.FETCH_PORTAL_TAG, payload: response.data, id })
  })
}

export const bulkAddProductsToTag = (id, formValues) => async dispatch => {
  dispatch({ type: Types.CLEAR_ERRORS, payload: '' })
  await branditRails
    .post(`/portal_tags/add_products_to_tag/${id}`, formValues)
    .then(function (response) {
      dispatch({
        type: Types.FETCH_PORTAL_TAG,
        payload: response.data,
        id
      })
    })
    .catch(function (error) {
      if (error.response.status == 422) {
        dispatch({
          type: Types.CREATE_SESSION_ERROR,
          payload: error.response.data
        })
      }
    })
}

export const deletePortalTagProduct = (id, product_id) => async dispatch => {
  await branditRails.delete(`/portal_tags/delete_portal_tag_product/${id}/${product_id}`).then(response => {
    dispatch({
      type: Types.FETCH_PORTAL_TAG,
      payload: response.data,
      id
    })
  })
}

export const updateDigitalProofingProduct = (id, formValues) => async dispatch => {
  await branditRails.patch(`/digital_proofing_products/${id}`, formValues).then(response => {
    dispatch({
      type: Types.UPDATE_DIGITAL_PROOFING_PRODUCT,
      payload: response.data,
      id: response.data.sub_order_item_id
    })
  })
}

export const generatePurchaseOrderPdf =
  (id, isInternalPoPdf = false) =>
  async dispatch => {
    const response = await branditRails.get(
      `/purchase_orders/purchase_order/${id}/generate_purchase_order?is_internal_po_pdf=${isInternalPoPdf}`
    )
    dispatch({
      type: Types.FETCH_PURCHASE_ORDER,
      payload: response.data,
      id
    })
  }

/**  Whitelisted IP */
export const fetchWhitelistedIps = () => async dispatch => {
  await branditRails.get('/whitelisted_ips').then(response => {
    dispatch({ type: Types.FETCH_WHITELISTED_IPS, payload: response.data })
  })
}

export const addWhitelistedIp = formValues => async dispatch => {
  await branditRails.post('/whitelisted_ips', formValues).then(response => {
    dispatch({ type: Types.CREATE_WHITELISTED_IP, payload: response.data })
  })
}

export const updateWhitelistedIp = (id, formValues) => async dispatch => {
  await branditRails.patch(`/whitelisted_ips/${id}`, formValues).then(response => {
    dispatch({ type: Types.EDIT_WHITELISTED_IP, payload: response.data })
  })
}

export const removeWhitelistedIp = id => async dispatch => {
  await branditRails.delete(`/whitelisted_ips/${id}`).then(response => {
    dispatch({ type: Types.FETCH_WHITELISTED_IPS, payload: response.data })
  })
}

/**  Blacklisted IP */
export const fetchBlacklistedIps = () => async dispatch => {
  await branditRails.get('/blacklisted_ips').then(response => {
    dispatch({ type: Types.FETCH_BLACKLISTED_IPS, payload: response.data })
  })
}

export const addBlacklistedIp = formValues => async dispatch => {
  await branditRails.post('/blacklisted_ips', formValues).then(response => {
    dispatch({ type: Types.CREATE_BLACKLISTED_IP, payload: response.data })
  })
}

export const updateBlacklistedIp = (id, formValues) => async dispatch => {
  await branditRails.patch(`/blacklisted_ips/${id}`, formValues).then(response => {
    dispatch({ type: Types.EDIT_BLACKLISTED_IP, payload: response.data })
  })
}

export const removeBlacklistedIp = id => async dispatch => {
  await branditRails.delete(`/blacklisted_ips/${id}`).then(response => {
    dispatch({ type: Types.FETCH_BLACKLISTED_IPS, payload: response.data })
  })
}

export const bulkUpdateArtworkOrder = artworkValues => async dispatch => {
  await branditRails.patch('/products/bulk_artwork_orders', artworkValues).then(function (response) {
    return new Promise((resolve, reject) => {
      dispatch({ type: Types.FETCH_PRODUCT, payload: response.data })
      resolve()
    })
  })
}

export const bulkUpdateCouponOrder = couponValues => async dispatch => {
  await branditRails.patch('/products/bulk_coupon_orders', couponValues).then(function (response) {
    return new Promise((resolve, reject) => {
      dispatch({ type: Types.FETCH_PRODUCT, payload: response.data })
      resolve()
    })
  })
}

// DROPSHIPPING & BATCHES
export const createDropshipBatch = formValues => async dispatch => {
  await branditRails.post('/dropship_batches', formValues).then(response => {
    toastr.success('Create Batch', 'Batch created successfully!', {
      className: css({ background: 'green !important' }),
      timeOut: 7000
    })
    dispatch({ type: Types.FETCH_DROPSHIP_BATCH, payload: response.data })
  })
}

export const fetchDropshipBatches =
  (statusFilter = null) =>
  async dispatch => {
    const url = statusFilter ? `/dropship_batches?status=${statusFilter}` : '/dropship_batches'

    await branditRails.get(url).then(response => {
      dispatch({ type: Types.FETCH_DROPSHIP_BATCHES, payload: response.data })
    })
  }

export const fetchDropshipBatch = id => async dispatch => {
  return await branditRails.get(`/dropship_batches/${id}`).then(response => {
    dispatch({ type: Types.FETCH_DROPSHIP_BATCH, payload: response.data })
    return response.data
  })
}

export const updateDropshipBatch = (batch_id, formValues) => async dispatch => {
  await branditRails.patch(`/dropship_batches/${batch_id}`, formValues).then(response => {
    toastr.success('Update Batch', 'Batch updated successfully!', {
      className: css({ background: 'green !important' })
    })
    dispatch({
      type: Types.FETCH_DROPSHIP_BATCH,
      payload: response.data
    })
  })
}

export const generateDropshipBatchPdf = id => async dispatch => {
  await branditRails.get(`/dropship_batches/${id}/generate_pdf`).then(response => {
    toastr.success('Generate PDF', 'PDF generated successfully!', {
      className: css({ background: 'green !important' })
    })
    dispatch({ type: Types.FETCH_DROPSHIP_BATCH, payload: response.data })
  })
}

export const generateDropshipBatchCsv = id => async dispatch => {
  await branditRails.get(`/dropship_batches/${id}/generate_csv`).then(response => {
    toastr.success('Generate CSV', 'CSV generated successfully!', {
      className: css({ background: 'green !important' })
    })
    dispatch({ type: Types.FETCH_DROPSHIP_BATCH, payload: response.data })
  })
}

export const generateDropshipBatchXml = id => async dispatch => {
  await branditRails.get(`/dropship_batches/${id}/generate_xml`).then(response => {
    toastr.success('Generate XML', 'XML generated successfully!', {
      className: css({ background: 'green !important' })
    })
    dispatch({ type: Types.FETCH_DROPSHIP_BATCH, payload: response.data })
  })
}

export const generateDropshipBatchSubOrderPdf = id => async dispatch => {
  return await branditRails.get(`/dropship_batches/generate_sub_order_pdf/${id}`).then(response => {
    toastr.success('Generate PDF', 'PDF generated successfully!', {
      className: css({ background: 'green !important' })
    })
    dispatch({ type: Types.FETCH_DROPSHIP_BATCH, payload: response.data })
    return response
  })
}

export const cancelDropshipBatch = id => async dispatch => {
  await branditRails.get(`/dropship_batches/${id}/cancel`).then(response => {
    toastr.success('Cancel Batch', 'Batch has been succesfully cancelled!', {
      className: css({ background: 'green !important' })
    })
    dispatch({ type: Types.FETCH_DROPSHIP_BATCHES, payload: [response.data] })
  })
}

export const fetchDropshipTemplates = () => async dispatch => {
  await branditRails.get('/dropship_templates').then(response => {
    dispatch({ type: Types.FETCH_DROPSHIP_TEMPLATES, payload: response.data })
  })
}

export const sendDropshipBatchEmail = (id, formValues) => async dispatch => {
  await branditRails.post(`/dropship_batches/${id}/send`, formValues).then(response => {
    if (response.data && response.data.errors) {
      response.data.errors.map(e => toastr.error('Error', e.message, { timeOut: 7000 }))
    } else {
      toastr.success('Email Sent', 'Email Sent Successfully!', {
        className: css({ background: 'green !important' })
      })
    }
  })
}

export const sendDropshipBatchFtp = (id, formValues) => async dispatch => {
  await branditRails.post(`/dropship_batches/${id}/send_ftp`, formValues).then(response => {
    if (response.data && response.data.errors) {
      response.data.errors.map(e => toastr.error('Error', e.message, { timeOut: 7000 }))
    } else {
      toastr.success('Success', 'File Uploaded via FTP Successfully!', {
        className: css({ background: 'green !important' })
      })
    }
  })
}

export const sendDropshipBatchSftp = (id, formValues) => async dispatch => {
  await branditRails.post(`/dropship_batches/${id}/send_sftp`, formValues).then(response => {
    if (response.data && response.data.errors) {
      response.data.errors.map(e => toastr.error('Error', e.message, { timeOut: 7000 }))
    } else {
      toastr.success('Success', 'File Uploaded via SFTP Successfully!', {
        className: css({ background: 'green !important' })
      })
    }
  })
}

export const sendDropshipBatchSubOrderEmail = (id, formValues) => async dispatch => {
  await branditRails.post(`/dropship_batch_sub_orders/${id}/send`, formValues).then(response => {
    if (response.data && response.data.errors) {
      response.data.errors.map(e => toastr.error('Error', e.message))
    } else {
      toastr.success('Sub Order Email Sent', 'Email Sent Successfully!', {
        className: css({ background: 'green !important' })
      })
    }
  })
}

export const markBatchAsSent = id => async dispatch => {
  await branditRails.get(`/dropship_batches/${id}/mark_as_sent`).then(response => {
    toastr.success('Success', 'Batch Successfully Marked as Sent')
    // history.back()
  })
}

export const fetchDashboard = () => async dispatch => {
  await branditRails.get('/admin_dashboard').then(resp => {
    dispatch({ type: 'SET_DASHBOARD', payload: resp.data })
  })
}

export const setMfError = (errorKey, errorMessage) => async dispatch => {
  dispatch({
    type: 'SET_MF_ERROR',
    payload: { key: errorKey, error: errorMessage }
  })
}

export const setDataGrid = () => async dispatch => {
  dispatch({
    type: 'SET_DATA_GRID',
    payload: { key: errorKey, error: errorMessage }
  })
}

export const clearMfError = () => async dispatch => {
  dispatch({ type: 'CLEAR_MF_ERRORS' })
}

// VUEXY ACTIONS
// ** Handles Layout Content Width (full / boxed)
export const handleContentWidth = value => dispatch => dispatch({ type: 'HANDLE_CONTENT_WIDTH', value })

// ** Handles Menu Collapsed State (Bool)
export const handleMenuCollapsed = value => dispatch => dispatch({ type: 'HANDLE_MENU_COLLAPSED', value })

// ** Handles Menu Hidden State (Bool)
export const handleMenuHidden = value => dispatch => dispatch({ type: 'HANDLE_MENU_HIDDEN', value })

// ** Handles RTL (Bool)
export const handleRTL = value => dispatch => dispatch({ type: 'HANDLE_RTL', value })

const optionsToParams = options => {
  return Object.entries(options).reduce((memo, [key, value], i) => {
    return `${memo}${key}=${value}${i === Object.keys(options).length - 1 ? '' : '&'}`
  }, '?')
}

// Add SKUs to locations
export const addSkusToLocations = (skus, locations) => async dispatch => {
  const [skuIds, locationIds] = [skus.map(s => s.id), locations.map(l => l.id)]
  if (skus.some(isProductNeedsConfiguration)) {
    toastr.error(
      'Product Not Configured',
      'One or more products are not configured. Please configure them before adding them to a portal.'
    )
    return
  }
  return await branditRails.post(`/products/add_to_locations`, { skuIds, locationIds }).then(response => {
    if (response.data && response.data.errors) {
      response.data.errors.map(e => toastr.error('Error', e.message))
      return false
    } else {
      // dispatch({ type: 'Types.FETCH_SKUS', payload: response.data })
      toastr.success('Success', 'Products added to locations successfully!')
      return true
    }
  })
}

// Update Requires Approval To Skus In Location
export const updateRequiresApprovalToLocationProducts = (products, locationId, approvalValue) => async dispatch => {
  const productIds = products.map(s => s.id)
  return await branditRails
    .post(`location_products/${locationId}/update_requires_approval_to_location_products`, {
      productIds,
      locationId,
      approvalValue
    })
    .then(response => {
      if (response.data && response.data.errors) {
        response.data.errors.map(e => toastr.error('Error', e.message))
        return false
      } else {
        const approvalText = approvalValue === 'add' ? 'added to' : 'removed from'
        toastr.success('Success', `Requires approval ${approvalText} products successfully!`)
        return true
      }
    })
}

// Checkout Form
export const createCheckoutForm = checkoutForm => async dispatch => {
  return await branditRails
    .post(`/checkout_forms`, checkoutForm)
    .then(response => {
      toastr.success('Checkout Form ', 'Checkout Form Created Successfully!')
      dispatch({ type: Types.CREATE_CHECKOUT_FORM, payload: response.data })
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

export const fetchCheckoutForms = clientId => async dispatch => {
  await branditRails.get(`/checkout_forms?client_id=${clientId}`).then(response => {
    dispatch({ type: Types.FETCH_CHECKOUT_FORMS, payload: response.data })
  })
}

export const fetchCheckoutForm = formId => async dispatch => {
  return await branditRails
    .get(`/checkout_forms/${formId}`)
    .then(response => {
      dispatch({ type: Types.FETCH_CHECKOUT_FORM, payload: response.data })
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

export const editCheckoutForm = (formId, data) => async dispatch => {
  return await branditRails
    .patch(`/checkout_forms/${formId}`, data)
    .then(response => {
      toastr.success('Checkout Form', 'Checkout Form Edited Successfully!')
      dispatch({ type: 'EDIT_CHECKOUT_FORM', payload: response.data })
      return response
    })
    .catch(function (error) {
      return error.response
    })
}

export const deleteInLineRow = (id, model) => async dispatch => {
  await branditRails
    .delete(`/mf/grid?table=${model}(${id})`)
    .then(response => {
      dispatch({ type: Types.DELETE_IN_LINE_ROW, payload: response.data })
      toastr.success('Success', 'Records Deleted Successfully!')
    })
    .catch(error => {
      if (error.response.status == 409) {
        toastr.error('Attention', error.response.data.message)
      } else {
        toastr.error('Error', 'Something went wrong!')
      }
    })
}

export const discardInLineRow = (id, model) => async dispatch => {
  await branditRails
    .patch(`/mf/grid/discard?table=${model}(${id})`)
    .then(response => {
      dispatch({ type: Types.DISCARD_IN_LINE_ROW, payload: response.data })
      toastr.success('Success', 'Order Error Delete Successfully!')
    })
    .catch(error => {
      if (error.response.status == 409) {
        toastr.error('Attention', error.response.data.message)
      } else {
        toastr.error('Error', 'Something went wrong!')
      }
    })
}

export const setLoading = (key, value) => async dispatch => {
  dispatch({ type: 'SET_LOADING', payload: [key, value] })
}

export const askForProductConfiguration = (product_id, history) => dispatch => {
  dispatch(
    dispatchSweetAlert({
      title: `This product is not yet configured. Would you like to configure it now?`,
      type: null,
      onConfirm: () => {
        history.push(`/products/${product_id}/configure`)
      },
      confirmBtnText: 'Yes, configure product!'
    })
  )
}

export const handleSidekiqDashboard = () => async dispatch => {
  await branditRails
    .get('/sidekiq_dashboard')
    .then(resp => {
      if (resp.status === 200) {
        window.open(resp.data.sidekiq_url, '_blank')
      }
    })
    .catch(error => {
      if (error.response.status === 401) {
        toastr.error('Unauthorized', 'Unauthorized to access Sidekiq Dashboard!')
      } else {
        toastr.error('Error', 'Something went wrong!')
      }
    })
}

export const generateOrderInvoice = orderId => async dispatch => {
  try {
    const response = await branditRails.get(`/orders/${orderId}/generate_invoice`)
    dispatch({ type: Types.FETCH_ORDER_INVOICE, payload: response.data, id: orderId })
    return response
  } catch (err) {
    toastr.error('Error', 'Something went wrong!')
  }
}

export const getOrderErrorsCount = () => async dispatch => {
  return await branditRails.get('/order_async_errors/count').then(response => {
    dispatch({ type: Types.FETCH_ORDER_ERRORS_COUNT, payload: response.data })
  })
}
