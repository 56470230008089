import { useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
// import defaultAvatar from '../../../../assets/images/logo/logo.png'
import { toastr } from 'react-redux-toastr'
import { Link } from 'react-router-dom'
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap'
import Avatar from '../../../../@core/components/avatar/index'
import branditRails from '../../../../api/brandit_rails'
import MfSelect from '../../../../components/general/MfSelect'

import { useMFAdminAppInitializer } from '../../../../contexts/MFAdminAppInitializer'
import { handleLogout } from '../../../../redux/actions/auth'

const UserDropdown = props => {
  const dispatch = useDispatch()
  const [isDesktopOrLaptop, setIsDesktopOrLaptop] = useState(window.innerWidth >= 768)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedPortalId, setSelectedPortalId] = useState(null)
  const storeNames = props.current_admin?.associated_portal_info?.map(portal => ({
    label: portal.portal_name,
    value: portal.portal_id
  }))
  // const currentSkin = window.localStorage.getItem('skin')
  // let { skin, setSkin } = props
  const {
    state: {
      layout: { skin }
    },
    actions: {
      layout: { SET_SKIN }
    }
  } = useMFAdminAppInitializer()

  // themes.current(`generic.${skin}`)

  // const applySkin = () => {
  //   SET_SKIN(skin === 'dark' ? 'light' : 'dark')
  //   if (skin === 'dark') {
  //     themes.current('generic.light')
  //     // themes.current('generic.dark')
  //   } else {
  //     themes.current('generic.dark')
  //     // themes.current('generic.light')
  //   }
  // }

  const ThemeToggler = useMemo(() => {
    const iconClassName = skin === 'dark' ? 'sun-bright' : 'moon'
    const toggleMode = skin === 'dark' ? 'Light' : 'Dark'

    const handleToggle = () => {
      SET_SKIN(skin === 'dark' ? 'light' : 'dark')
    }

    return () => (
      <DropdownItem onClick={handleToggle} style={{ marginTop: '5px' }}>
        <div style={{ minWidth: '240px' }}>
          <i
            className={`fa-regular fa-${iconClassName}`}
            aria-label="Profile (Future)"
            style={{ marginRight: '0.4375rem' }}
          />
          {toggleMode}
        </div>
      </DropdownItem>
    )
  }, [skin, SET_SKIN])

  // const ThemeToggler = () => (
  //   <DropdownItem onClick={() => applySkin()} style={{ marginTop: '5px' }}>
  //     {skin === 'dark' ? (
  //       <div style={{ minWidth: '240px' }}>
  //         <i className="fa-regular fa-sun-bright" aria-label="Profile (Future)" style={{ marginRight: '0.5rem' }}></i>
  //         Light Mode
  //         {/* <Sun className="ficon" style={{ marginRight: '0.3rem' }} /> Light Mode */}
  //       </div>
  //     ) : (
  //       <div style={{ minWidth: '240px' }}>
  //         <i className="fa-regular fa-moon" aria-label="Profile (Future)" style={{ marginRight: '0.5rem' }}></i>
  //         Dark Mode
  //         {/* <Moon className="ficon" style={{ marginRight: '0.3rem' }} /> Dark Mode */}
  //       </div>
  //     )}
  //   </DropdownItem>
  // )

  const toggleModal = () => {
    setModalOpen(!modalOpen)
    setSelectedPortalId(null)
  }

  const handlePortalSelect = option => setSelectedPortalId(option.value)

  const onClickLogout = () => {
    dispatch(handleLogout(props.current_admin))
  }

  const adminType = index => {
    if (props.current_admin !== undefined) {
      if (props.current_admin && props.current_admin.is_super_admin === true) {
        return <span>&nbsp; Super Admin </span>
      }
      if (props.current_admin.is_client_admin === true) {
        return 'Client Admin'
      }
      return 'Admin'
    } else {
      return 'Please refresh...'
    }
  }
  const ssoLoginPrivateWindow = (selectedPortalId = null) => {
    const userId = props.current_admin.user.id
    let portalId = null
    if (!selectedPortalId && props.current_admin.associated_portal_info.length > 1) {
      toggleModal()
      return null
    }

    if (selectedPortalId !== null) {
      portalId = selectedPortalId
      setModalOpen(false)
    } else if (props.current_admin.associated_portal_info.length === 1) {
      portalId = props.current_admin.associated_portal_info[0].portal_id
    }

    if (!portalId) {
      console.error('No portal ID found. Please contact administrator.')
      return null
    }

    branditRails
      .get(`/get_sso_url_customer_show/user_id/${userId}/portal_id/${portalId}`)
      .then(response => {
        if (response.data.error) {
          toastr.error(`Error: ${response.data.error}, Please contact administrator.`)
        } else {
          const url = response.data.url

          if (url) {
            window.location.href = url
          }
        }
      })
      .catch(err => {
        toastr.error(`Could not log in as customer. Error: ${err}`)
      })
  }

  // const userAvatar = defaultAvatar
  return (
    <>
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle
          href="/"
          tag="a"
          className={`nav-link dropdown-user-link custom-dropdown-toggle ${
            skin === 'dark' ? 'custom-dropdown-toggle-dark' : ''
          }`}
          onClick={e => e.preventDefault()}
        >
          <div className={`${isDesktopOrLaptop ? 'dropdown-header' : ''} text-center`}>
            <div className="d-flex align-items-center justify-content-center">
              <span
                className="d-none d-md-inline"
                style={{ marginRight: '10px', color: skin === 'dark' ? '#D0D2D6' : '' }}
              >
                My Account
              </span>
              <span>
                <Avatar />
              </span>
            </div>
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu custom-dropdown-menu">
          {props.current_admin.is_client_admin && props.current_admin.user && (
            <>
              <Button
                size="sm"
                disabled={props.current_admin?.user?.sso_id ? false : true}
                onClick={() => ssoLoginPrivateWindow(selectedPortalId)}
                style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <i
                  className="fa-regular fa-shield-keyhole"
                  aria-label="Access Store"
                  style={{ marginRight: '0.4375rem' }}
                ></i>
                Access Store
              </Button>
              <DropdownItem divider />
            </>
          )}
          <div className="user-nav d-flex flex-column">
            <div className="user-name font-weight-bold">
              Hi, {(props.current_admin && props.current_admin.name) || 'User'}
            </div>
            <div className="user-status" style={{ marginTop: '5px' }}>
              {adminType() || 'Loading...'}
            </div>
          </div>
          <ThemeToggler />
          {/* <ApplySkin /> */}
          <DropdownItem tag={Link} to="/profile" disabled>
            <i className="fa-regular fa-user" aria-label="Profile (Future)" style={{ marginRight: '0.4375rem' }}></i>
            Profile (Coming soon)
          </DropdownItem>
          <DropdownItem tag={Link} to="/preferences" disabled>
            <i
              className="fa-regular fa-gear"
              aria-label="Preferences (Future)"
              style={{ marginRight: '0.4375rem' }}
            ></i>
            Preferences (Coming soon)
          </DropdownItem>
          <DropdownItem tag={Link} to="/update_password">
            <i className="fa-regular fa-key" aria-label="Update Password" style={{ marginRight: '0.4375rem' }}></i>
            Update Password
          </DropdownItem>
          <DropdownItem tag={Link} to="/billing" disabled>
            <i
              className="fa-regular fa-file-invoice-dollar"
              aria-label="Billing (Future)"
              style={{ marginRight: '0.4375rem' }}
            ></i>
            Billing (Coming soon)
          </DropdownItem>
          <DropdownItem divider />
          <Button
            size="sm"
            color="primary"
            onClick={onClickLogout}
            style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <i
              className="fa-regular fa-right-from-bracket"
              aria-label="Sign Out"
              style={{ marginRight: '0.4375rem' }}
            ></i>
            Sign Out
          </Button>

          <div style={{ marginTop: '10px' }}>
            <span style={{ marginRight: '10px' }}>
              <a href="#!" className="text-muted" style={{ pointerEvents: 'none', textDecoration: 'none' }}>
                Terms and Conditions
              </a>
            </span>
            |
            <span style={{ marginLeft: '10px' }}>
              <a href="#!" className="text-muted" style={{ pointerEvents: 'none', textDecoration: 'none' }}>
                Contact Us
              </a>
            </span>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          className="modal-dialog-centered"
          style={{ maxWidth: '400px', width: '100%' }}
        >
          <ModalBody>
            <div style={{ textAlign: 'center', fontSize: '3.5rem', marginBottom: '0.875rem' }}>
              <i className="fa-regular fa-shield-keyhole" aria-label="Access Store"></i>
            </div>
            <p style={{ textAlign: 'center' }}>
              There are multiple stores associated with your account. Please select one to continue.
            </p>
            <MfSelect
              options={storeNames}
              placeholder="Select Store"
              onChange={handlePortalSelect}
              value={selectedPortalId ? storeNames.find(store => store.value === selectedPortalId) : null}
              getOptionValue={opt => opt.value}
              getOptionLabel={opt => opt.label}
              isClearable={true}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
            <Button color="primary" onClick={() => ssoLoginPrivateWindow(selectedPortalId)}>
              Ok
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    current_admin: state.admins.current_admin
  }
}

export default connect(mapStateToProps)(UserDropdown)
