// CLIENTS
export const CREATE_CLIENT = 'CREATE_CLIENT'
export const FETCH_CLIENTS = 'FETCH_CLIENTS'
export const EDIT_CLIENT = 'EDIT_CLIENT'
export const FETCH_CLIENT = 'FETCH_CLIENT'
export const DELETE_CLIENT = 'DELETE_CLIENT'
export const DELETE_CLIENT_LOGO = 'DELETE_CLIENT_LOGO'
export const FETCH_CLIENT_CC_DATA_POINT = 'FETCH_CLIENT_CC_DATA_POINT'

// CLIENT_IMAGES
export const CREATE_CLIENT_IMAGE = 'CREATE_CLIENT_IMAGE'
export const FETCH_CLIENT_IMAGES = 'FETCH_CLIENT_IMAGES'
export const DELETE_CLIENT_IMAGE = 'DELETE_CLIENT_IMAGE'
export const EDIT_CLIENT_IMAGE = 'EDIT_CLIENT_IMAGE'

// IN_LINE_ROW
export const DELETE_IN_LINE_ROW = 'DELETE_IN_LINE_ROW'
export const DISCARD_IN_LINE_ROW = 'DISCARD_IN_LINE_ROW'

//SETTINGS
export const FETCH_SETTINGS = 'FETCH_SETTINGS'
export const EDIT_SETTING = 'EDIT_SETTING'
export const FETCH_SETTING = 'FETCH_SETTING'
export const DELETE_SETTING = 'DELETE_SETTING'

// PORTALS
export const CREATE_PORTAL = 'CREATE_PORTAL'
export const FETCH_PORTALS = 'FETCH_PORTALS'
export const FETCH_PORTAL = 'FETCH_PORTAL'
export const EDIT_PORTAL = 'EDIT_PORTAL'
export const DELETE_PORTAL = 'DELETE_PORTAL'
export const FETCH_PORTAL_ACCOUNTS = 'FETCH_PORTAL_ACCOUNTS'
export const FETCH_PORTAL_TRADITIONAL_DELIVERY_ADDRESSES = 'FETCH_PORTAL_TRADITIONAL_DELIVERY_ADDRESSES'

//PERIODS
export const FETCH_PORTAL_PERIODS = 'FETCH_PORTAL_PERIODS'
export const EDIT_PERIOD = 'EDIT_PERIOD'
export const FETCH_PERIOD = 'FETCH_PERIOD'
export const CREATE_PERIOD = 'CREATE_PERIOD'
export const DELETE_PERIOD = 'DELETE_PERIOD'
export const SEND_BALANCE_REMINDERS = 'SEND_BALANCE_REMINDERS'
export const GET_LAST_REMINDER_SENT = 'GET_LAST_REMINDER_SENT'

// PAGES
export const CREATE_PAGE = 'CREATE_PAGE'
export const FETCH_PORTAL_PAGES = 'FETCH_PORTAL_PAGES'
export const FETCH_PAGE = 'FETCH_PAGE'
export const EDIT_PAGE = 'EDIT_PAGE'
export const DELETE_PAGE = 'DELETE_PAGE'
export const BULK_UPDATE_PAGES = 'BULK_UPDATE_PAGES'
export const SET_PAGES_LOADING = 'SET_PAGES_LOADING'

// DOMAINS
export const FETCH_DOMAIN = 'FETCH_DOMAIN'
export const FETCH_PORTAL_DOMAINS = 'FETCH_PORTAL_DOMAINS'
export const CREATE_DOMAIN = 'CREATE_DOMAIN'
export const EDIT_DOMAIN = 'EDIT_DOMAIN'
export const DELETE_DOMAIN = 'DELETE_DOMAIN'

//TEMPLATES
export const FETCH_GENERIC_TEMPLATE = 'FETCH_GENERIC_TEMPLATE'
export const EDIT_GENERIC_TEMPLATE = 'EDIT_GENERIC_TEMPLATE'
export const FETCH_HOME_TEMPLATE = 'FETCH_HOME_TEMPLATE'
export const EDIT_HOME_TEMPLATE = 'EDIT_HOME_TEMPLATE'
export const FETCH_ABOUT_TEMPLATE = 'FETCH_ABOUT_TEMPLATE'
export const EDIT_ABOUT_TEMPLATE = 'EDIT_ABOUT_TEMPLATE'

//AUDITS
export const FETCH_AUDITS = 'FETCH_AUDITS'

//CARTS
export const FETCH_CARTS = 'FETCH_CARTS'

//CREDITS
export const FETCH_CREDITS = 'FETCH_CREDITS'
export const CREATE_CREDITS = 'CREATE_CREDITS'
export const FETCH_CREDIT_BALANCES = 'FETCH_CREDIT_BALANCES'
export const UPLOAD_RESULTS = 'UPLOAD_RESULTS'
export const UPLOAD_HISTORY = 'UPLOAD_HISTORY'
export const FETCH_EXTERNAL_ACCOUNTS = 'FETCH_EXTERNAL_ACCOUNTS'
export const UPDATE_EXTERNAL_IDS = 'UPDATE_EXTERNAL_IDS'

//PRODUCTS
export const FETCH_CLIENT_PRODUCTS = 'FETCH_CLIENT_PRODUCTS'
export const FETCH_PORTAL_PRODUCTS = 'FETCH_PORTAL_PRODUCTS'
export const UPDATE_PORTAL_PRODUCTS = 'UPDATE_PORTAL_PRODUCTS'
export const FETCH_LOCATION_PRODUCTS = 'FETCH_LOCATION_PRODUCTS'
export const ADD_PORTAL_PRODUCTS = 'ADD_PORTAL_PRODUCTS'
export const ADD_PORTAL_FEATURED_PRODUCTS = 'ADD_PORTAL_FEATURED_PRODUCTS'
export const ADD_LOCATION_PRODUCTS = 'ADD_LOCATION_PRODUCTS'
export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const FETCH_PRODUCT = 'FETCH_PRODUCT'
export const EDIT_PRODUCT = 'EDIT_PRODUCT'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const FETCH_AGGREGATED_PORTAL_PRODUCT = 'FETCH_AGGREGATED_PORTAL_PRODUCT'
export const FETCH_AGGREGATED_CLIENT_PRODUCT = 'FETCH_AGGREGATED_CLIENT_PRODUCT'
export const FETCH_AGGREGATED_LOCATION_PRODUCT = 'FETCH_AGGREGATED_LOCATION_PRODUCT'
export const FETCH_AGGREGATED_GENERIC_PRODUCT = 'FETCH_AGGREGATED_GENERIC_PRODUCT'
export const EDIT_PORTAL_PRODUCT = 'EDIT_PORTAL_PRODUCT'
export const EDIT_LOCATION_PRODUCT = 'EDIT_LOCATION_PRODUCT'
export const EDIT_GENERIC_PRODUCT = 'EDIT_GENERIC_PRODUCT'
export const EDIT_CLIENT_PRODUCT = 'EDIT_CLIENT_PRODUCT'
export const FETCH_PRODUCT_SKU = 'FETCH_PRODUCT_SKU'
export const FETCH_PRODUCT_SKUS = 'FETCH_PRODUCT_SKUS'
export const REMOVE_PORTAL_PRODUCT = 'REMOVE_PORTAL_PRODUCT'
export const REMOVE_PORTAL_FEATURED_PRODUCT = 'REMOVE_PORTAL_FEATURED_PRODUCT'
export const REMOVE_LOCATION_PRODUCT = 'REMOVE_LOCATION_PRODUCT'
export const FETCH_BUNDLE_PRODUCTS = 'FETCH_BUNDLE_PRODUCTS'

//ERRORS
export const CREATE_SESSION_ERROR = 'CREATE_SESSION_ERROR'
export const CREATE_VALIDATION_ERROR = 'CREATE_VALIDATION_ERROR'
export const REQUIRED_ERROR_ACTION = 'REQUIRED_ERROR_ACTION'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

//STATUSES
export const DATA_FETCHING = 'DATA_FETCHING'
export const DATA_FETCHED = 'DATA_FETCHED'
export const CLEAR_STATUS = 'CLEAR_STATUS'

//ADMINS
export const FETCH_ADMINS = 'FETCH_ADMINS'
export const FETCH_ADMIN = 'FETCH_ADMIN'
export const FETCH_CLIENT_ADMINS = 'FETCH_CLIENT_ADMINS'
export const FETCH_CLIENT_ADMINS_BY_USER = 'FETCH_CLIENT_ADMINS_BY_USER'
export const CREATE_ADMIN = 'CREATE_ADMIN'
export const EDIT_ADMIN = 'EDIT_ADMIN'
export const DELETE_ADMIN = 'DELETE_ADMIN'
export const ADMIN_PERMISSIONS = 'ADMIN_PERMISSIONS'
export const ADMIN_HAS_ROLES = 'ADMIN_HAS_ROLES'
export const CLEAR_ADMINS = 'CLEAR_ADMINS'
export const FETCH_AUTHORIZED_ADMIN_EMAIL_DOMAINS = 'FETCH_AUTHORIZED_ADMIN_EMAIL_DOMAINS'
export const FETCH_FUNDING_TAGS = 'FETCH_FUNDING_TAGS'
export const CLEAR_FUNDING_TAGS = 'CLEAR_FUNDING_TAGS'
export const FETCH_COOP_BALANCES_BY_TAG = 'FETCH_COOP_BALANCES_BY_TAG'
export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const FORGOT_ADMIN_PASSWORD_REQUEST = 'FORGOT_ADMIN_PASSWORD_REQUEST'
export const FORGOT_ADMIN_PASSWORD_SUCCESS = 'FORGOT_ADMIN_PASSWORD_SUCCESS'
export const RESET_ADMIN_PASSWORD_REQUEST = 'RESET_ADMIN_PASSWORD_REQUEST'
export const RESET_ADMIN_PASSWORD_SUCCESS = 'RESET_ADMIN_PASSWORD_SUCCESS'
export const VALIDATE_ADMIN_RESET_TOKEN_REQUEST = 'VALIDATE_ADMIN_RESET_TOKEN_REQUEST'
export const VALIDATE_ADMIN_RESET_TOKEN_SUCCESS = 'VALIDATE_ADMIN_RESET_TOKEN_SUCCESS'
export const VERIFY_ADMIN_PASSWORD_TOKEN_SUCCESS = 'VERIFY_ADMIN_PASSWORD_TOKEN_SUCCESS'
export const VERIFY_ADMIN_PASSWORD_TOKEN_FAILURE = 'VERIFY_ADMIN_PASSWORD_TOKEN_FAILURE'

//USERS
export const FETCH_USERS = 'FETCH_USERS'
export const FETCH_USER = 'FETCH_USER'
export const CREATE_USER = 'CREATE_USER'
export const EDIT_USER = 'EDIT_USER'
export const DELETE_USER = 'DELETE_USER'
export const FETCH_USER_BY_EMAIL = 'FETCH_USER_BY_EMAIL'
export const FETCH_USER_PORTALS = 'FETCH_USER_PORTALS'
export const FETCH_USER_LOCATIONS = 'FETCH_USER_LOCATIONS'
export const VALIDATE_PRODUCT = 'VALIDATE_PRODUCT'

// MARKETS / LOCATIONS
export const CREATE_LOCATION = 'CREATE_LOCATION'
export const FETCH_LOCATIONS = 'FETCH_LOCATIONS'
export const FETCH_LOCATION = 'FETCH_LOCATION'
export const ASSIGN_LOCATIONS_TO_LOCATION_GROUP = 'ASSIGN_LOCATIONS_TO_LOCATION_GROUP'
export const DELETE_LOCATION_GROUP = 'DELETE_LOCATION_GROUP'
export const REFRESH_LOCATION_GROUPS = 'REFRESH_LOCATION_GROUPS'
export const EDIT_LOCATION = 'EDIT_LOCATION'
export const DELETE_LOCATION = 'DELETE_LOCATION'
export const BULK_UPDATE_LOCATIONS = 'BULK_UPDATE_LOCATIONS'
export const FETCH_CLIENT_LOCATIONS = 'FETCH_CLIENT_LOCATIONS'

//FETCH_VENDORS
export const FETCH_VENDORS = 'FETCH_VENDORS'
export const CREATE_VENDOR = 'CREATE_VENDOR'
export const EDIT_VENDOR = 'EDIT_VENDOR'
export const FETCH_VENDOR = 'FETCH_VENDOR'
export const DELETE_VENDOR = 'DELETE_VENDOR'

// CATEGORIES
export const CREATE_CATEGORY = 'CREATE_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const EDIT_CATEGORY_VISIBILITY = 'EDIT_CATEGORY_VISIBILITY'
export const FETCH_CATEGORY = 'FETCH_CATEGORY'
export const FETCH_OVERIDDEN_CATEGORY = 'FETCH_OVERIDDEN_CATEGORY'
export const FETCH_CATEGORY_PRODUCTS = 'FETCH_CATEGORY_PRODUCTS'
export const FETCH_LOCATION_SUB_CATEGORIES = 'FETCH_LOCATION_SUB_CATEGORIES'
export const CREATE_CATEGORY_PRODUCTS = 'CREATE_CATEGORY_PRODUCTS'
export const FETCH_LOCATION_CATEGORY_PRODUCTS = 'FETCH_LOCATION_CATEGORY_PRODUCTS'
export const OVERRIDE_CATEGORY = 'OVERRIDE_CATEGORY'
export const FETCH_PORTAL_CATEGORIES = 'FETCH_PORTAL_CATEGORIES'

// PRODUCT CONFIGURABLE VARIANTS
export const CREATE_PRODUCT_CONFIGURABLE_VARIANT = 'CREATE_PRODUCT_CONFIGURABLE VARIANT'
export const EDIT_PRODUCT_CONFIGURABLE_VARIANT = 'EDIT_PRODUCT_CONFIGURABLE VARIANT'
export const DELETE_PRODUCT_CONFIGURABLE_VARIANT = 'DELETE_PRODUCT_CONFIGURABLE_VARIANT'
export const FETCH_PRODUCT_CONFIGURABLE_VARIANT = 'FETCH_PRODUCT_CONFIGURABLE_VARIANT'
export const FETCH_PRODUCT_CONFIGURABLE_VARIANTS = 'FETCH_PRODUCT_CONFIGURABLE_VARIANTS'
export const BULK_UPDATE_CONFIGURABLE_VARIANTS = 'BULK_UPDATE_CONFIGURABLE_VARIANTS'

// CONFIGURABLE OPTIONS
export const CREATE_CONFIGURABLE_OPTION_DETAILS = 'CREATE_CONFIGURABLE_OPTION_DETAILS'
export const CREATE_CONFIGURABLE_OPTION = 'CREATE_CONFIGURABLE_OPTION'
export const EDIT_CONFIGURABLE_OPTION = 'EDIT_CONFIGURABLE_OPTION'
export const DELETE_CONFIGURABLE_OPTION = 'DELETE_CONFIGURABLE_OPTION'
export const FETCH_CONFIGURABLE_OPTION = 'FETCH_CONFIGURABLE_OPTION'
export const FETCH_CONFIGURABLE_OPTIONS = 'FETCH_CONFIGURABLE_OPTIONS'
export const FETCH_PRODUCT_ASSIGNED_VARIANTS = 'FETCH_PRODUCT_ASSIGNED_VARIANTS'
export const FETCH_VARIANT_PRODUCTS = 'FETCH_VARIANT_PRODUCTS'

// Product Config Wizard Form
export const FETCH_SKU_PREVIEW = 'FETCH_SKU_PREVIEW'
export const CLEAR_CONFIG_FORM = 'CLEAR_CONFIG_FORM'
export const CREATE_VARIANT_SKU = 'CREATE_VARIANT_SKU'
export const CLEAR_CONFIG_WIZARD_FORM = 'CLEAR_CONFIG_WIZARD_FORM'

//User Groups
export const CREATE_CUSTOMER_GROUP = 'CREATE_CUSTOMER_GROUP'
export const FETCH_CUSTOMER_GROUP = 'FETCH_CUSTOMER_GROUP'
export const FETCH_CUSTOMER_GROUPS = 'FETCH_CUSTOMER_GROUPS'
export const FETCH_USER_PORTALS_CUSTOMER_GROUPS = 'FETCH_USER_PORTALS_CUSTOMER_GROUPS'
export const CREATE_CUSTOMER_GROUP_RULE = 'CREATE_CUSTOMER_GROUP_RULE'
export const EDIT_CUSTOMER_GROUP_RULE = 'EDIT_CUSTOMER_GROUP_RULE'
export const FETCH_CUSTOMER_GROUP_RULE = 'FETCH_CUSTOMER_GROUP_RULE'
export const FETCH_PORTAL_CUSTOMER_GROUP_RULES = 'FETCH_PORTAL_CUSTOMER_GROUP_RULES'
export const FETCH_PORTAL_USERS = 'FETCH_PORTAL_USERS'
export const FETCH_CUSTOMER_GROUPS_FOR_USER = 'FETCH_CUSTOMER_GROUPS_FOR_USER'
export const FETCH_WAREHOUSES = 'FETCH_WAREHOUSES'
export const CREATE_WAREHOUSE = 'CREATE_WAREHOUSE'
export const FETCH_WAREHOUSE = 'FETCH_WAREHOUSE'
export const EDIT_WAREHOUSE = 'EDIT_WAREHOUSE'
export const FETCH_WAREHOUSES_FILTERED_BY_PRODUCTS = 'FETCH_WAREHOUSES_FILTERED_BY_PRODUCTS'
export const FETCH_PRODUCT_SKUS_SORTED = 'FETCH_PRODUCT_SKUS_SORTED'
export const TOGGLE_PRODUCT_SKU_VISIBLITY = 'TOGGLE_PRODUCT_SKU_VISIBLITY'
export const FETCH_WAREHOUSE_STOCK = 'FETCH_WAREHOUSE_STOCK'
export const CREATE_WAREHOUSE_STOCK = 'CREATE_WAREHOUSE_STOCK'
export const EDIT_WAREHOUSE_STOCKS = 'EDIT_WAREHOUSE_STOCKS'
export const DELETE_WAREHOUSE = 'DELETE_WAREHOUSE'
export const FETCH_SHIP_FROM_ADDRESSES = 'FETCH_SHIP_FROM_ADDRESSES'
export const FETCH_PRODUCT_PORTALS = 'FETCH_PRODUCT_PORTALS'
export const FETCH_PRODUCT_LOCATIONS = 'FETCH_PRODUCT_LOCATIONS'
export const FETCH_PRODUCT_CLIENTS = 'FETCH_PRODUCT_CLIENTS'
export const FETCH_INVENTORY_LOG = 'FETCH_INVENTORY_LOG'

// CONTENT BLOCKS
export const CREATE_PAGE_CONTENT = 'CREATE_PAGE_CONTENT'
export const UPDATE_CONTENT_BLOCK = 'UPDATE_CONTENT_BLOCK'
export const DELETE_CONTENT_BLOCK = 'DELETE_CONTENT_BLOCK'
export const CREATE_FAQ_ITEMS = 'CREATE_FAQ_ITEMS'
export const CREATE_SLIDER_ITEMS = 'CREATE_SLIDER_ITEMS'
export const CREATE_LINK_COL_ITEMS = 'CREATE_LINK_COL_ITEMS'
export const CREATE_FEATURE_ITEMS = 'CREATE_FEATURE_ITEMS'
export const CREATE_PROSLIDER_ITEMS = 'CREATE_PROSLIDER_ITEMS'

// ORDERS
export const FETCH_ORDERS = 'FETCH_ORDERS'
export const FETCH_ORDER = 'FETCH_ORDER'
export const FETCH_PENDING_ORDERS = 'FETCH_PENDING_ORDERS'
export const UPDATE_ORDER = 'UPDATE_ORDER'
export const SPLIT_ORDER = 'SPLIT_ORDER'
export const FETCH_SPLITTED_SUB_ORDERS = 'FETCH_SPLITTED_SUB_ORDERS'
export const FILTER_SPLITTED_SUB_ORDERS = 'FILTER_SPLITTED_SUB_ORDERS'
export const FETCH_SUB_ORDERS = 'FETCH_SUB_ORDERS'
export const UPDATE_SUB_ORDER = 'UPDATE_SUB_ORDER'
export const MERGE_SUB_ORDERS = 'MERGE_SUB_ORDERS'
export const CANCEL_ORDER = 'CANCEL_ORDER'
export const FETCH_ORDER_INVOICE = 'FETCH_ORDER_INVOICE'
export const FETCH_ORDER_ERRORS_COUNT = 'FETCH_ORDER_ERRORS_COUNT'

//MENUS
export const FETCH_MENU_ITEMS = 'FETCH_MENU_ITEMS'
export const CREATE_MENU_ITEM = 'CREATE_MENU_ITEM'
export const UPDATE_MENU_ITEM = 'UPDATE_MENU_ITEM'
export const FETCH_MENU_ITEM = 'FETCH_MENU_ITEM'
export const DELETE_MENU_ITEM = 'DELETE_MENU_ITEM'
export const BULK_UPDATE_MENU_ITEMS = 'BULK_UPDATE_MENU_ITEMS'

// AUTHORIZE.NET
export const FETCH_CARD_PAYMENTS = 'FETCH_CARD_PAYMENTS'
export const CHECK_SYSTEM_SETTINGS = 'CHECK_SYSTEM_SETTINGS'

export const FETCH_IMAGE = 'FETCH_IMAGE'
export const DELETE_IMAGE = 'DELETE_IMAGE'
export const DELETE_CHILD_IMAGE = 'DELETE_CHILD_IMAGE'

// BUNDLES
export const ADD_BUNDLE = 'ADD_BUNDLE'
export const FETCH_BUNDLES = 'FETCH_BUNDLES'
export const FETCH_BUNDLE = 'FETCH_BUNDLE'
export const ADD_BUNDLE_GROUP = 'ADD_BUNDLE_GROUP'
export const CLEAR_BUNDLE_GROUP_PRODUCTS = 'CLEAR_BUNDLE_GROUP_PRODUCTS'
export const FETCH_BUNDLE_GROUP_PRODUCTS = 'FETCH_BUNDLE_GROUP_PRODUCTS'
export const UPDATE_BUNDLE_GROUP_PRODUCT = 'UPDATE_BUNDLE_GROUP_PRODUCT'
export const FETCH_ASSOC_BUNDLES = 'FETCH_ASSOC_BUNDLES'
export const EDIT_BUNDLE = 'EDIT_BUNDLE'

// ARTWORKS
export const FETCH_ARTWORK = 'FETCH_ARTWORK'
export const FETCH_ARTWORKS = 'FETCH_ARTWORKS'
export const DELETE_ARTWORK = 'DELETE_ARTWORK'
export const EDIT_ARTWORK = 'EDIT_ARTWORK'
export const PREVIEW_ARTWORK = 'PREVIEW_ARTWORK'

// ARTWORK FAMILIES
export const FETCH_ARTWORK_FAMILY = 'FETCH_ARTWORK_FAMILY'
export const FETCH_ARTWORK_FAMILIES = 'FETCH_ARTWORK_FAMILIES'
export const DELETE_ARTWORK_FAMILY = 'DELETE_ARTWORK_FAMILY'

//TAGS
export const FETCH_TAGS = 'FETCH_TAGS'
export const FETCH_PROMOTIONS = 'FETCH_PROMOTIONS'
export const CREATE_PROMOTION = 'CREATE_PROMOTION'
export const FETCH_PROMOTION = 'FETCH_PROMOTION'
export const DELETE_PROMOTION = 'DELETE_PROMOTION'
export const CREATE_PROMOTION_RULE = 'CREATE_PROMOTION_RULE'
export const FETCH_PROMOTION_RULE = 'FETCH_PROMOTION_RULE'
export const FETCH_PROMOTION_CODE = 'FETCH_PROMOTION_CODE'
export const CREATE_PROMOTION_VALIDATION = 'CREATE_PROMOTION_VALIDATION'
export const FETCH_PROMOTION_VALIDATION = 'FETCH_PROMOTION_VALIDATION'
export const CREATE_PROMOTION_CODE = 'CREATE_PROMOTION_CODE'
export const CREATE_PROMOTION_CODES = 'CREATE_PROMOTION_CODES'

//SMART ADDRESS
export const FETCH_SMART_ADDRESS = 'FETCH_SMART_ADDRESS'

//REFUND_ORDERS
export const FETCH_REFUNDS = 'FETCH_REFUNDS'
export const CREATE_REFUND = 'CREATE_REFUND'
export const FETCH_REFUND = 'FETCH_REFUND'
export const FETCH_REFUND_SUMMARY = 'FETCH_REFUND_SUMMARY'
export const FETCH_ORDER_AFTER_REFUNDS = 'ORDER_AFTER_REFUNDS'
export const FETCH_ORDER_REFUND_STATUSES = 'FETCH_ORDER_REFUND_STATUSES'
export const FETCH_CARRIERS = 'FETCH_CARRIERS'
export const FETCH_CARRIERS_IDS = 'FETCH_CARRIERS_IDS'
export const FETCH_CARRIER_METHODS = 'FETCH_CARRIER_METHODS'
export const FETCH_SHIPPING_LABEL = 'FETCH_SHIPPING_LABEL'
export const FETCH_PAGINATED = 'FETCH_PAGINATED'
export const FETCH_PAGINATED_CLEAR = 'FETCH_PAGINATED_CLEAR'
export const FETCH_CARRIER_RATES = 'FETCH_CARRIER_RATES'
export const FETCH_CARRIER_RATES_MULTI_BOX = 'FETCH_CARRIER_RATES_MULTI_BOX'
export const FETCH_THIRD_PARTY_BILLINGS = 'FETCH_THIRD_PARTY_BILLINGS'
export const CREATE_THIRD_PARTY_BILLING = 'CREATE_THIRD_PARTY_BILLING'

//PURCHASE ORDERS
export const CREATE_PURCHASE_ORDER = 'CREATE_PURCHASE_ORDER'
export const EDIT_PURCHASE_ORDER = 'EDIT_PURCHASE_ORDER'
export const FETCH_PURCHASE_ORDER = 'FETCH_PURCHASE_ORDER'
export const GENERATE_PURCHASE_ORDER_PDF = 'GENERATE_PURCHASE_ORDER_PDF'

//PURCHASE ORDER ITEMS
export const FETCH_PURCHASE_ORDER_ITEMS = 'FETCH_PURCHASE_ORDER_ITEMS'
export const DELETE_PURCHASE_ORDER_ITEM = 'DELETE_PURCHASE_ORDER_ITEM'

//GOOD RECEIPTS
export const CREATE_GOODS_RECEIPT = 'CREATE_GOODS_RECEIPT'
export const UPDATE_PURCHASE_ORDER_ITEM = 'UPDATE_PURCHASE_ORDER_ITEM'
export const FETCH_GOODS_RECEIPT_LOGS = 'FETCH_GOODS_RECEIPT_LOGS'

// SHIPPING HQ
export const FETCH_SHQ_SHIPPING_GROUPS = 'FETCH_SHQ_SHIPPING_GROUPS'
export const FETCH_SHQ_ORIGINS = 'FETCH_SHQ_ORIGINS'
export const FETCH_SHQ_PACKING_RULES = 'FETCH_SHQ_PACKING_RULES'
export const FETCH_SHQ_BOXES = 'FETCH_SHQ_BOXES'
export const FETCH_PRODUCT_SHQ_SHIPPING_GROUPS = 'FETCH_PRODUCT_SHQ_SHIPPING_GROUPS'
export const FETCH_PRODUCT_SHQ_SHIPPING_ORIGINS = 'FETCH_PRODUCT_SHQ_SHIPPING_ORIGINS'
export const FETCH_PRODUCT_SHQ_SHIPPING_BOXES = 'FETCH_PRODUCT_SHQ_SHIPPING_BOXES'
export const FETCH_PRODUCT_SHQ_PACKING_RULE = 'FETCH_PRODUCT_SHQ_PACKING_RULE'
export const FETCH_SHIPMENT_STATUS = 'FETCH_SHIPMENT_STATUS'
export const NOTIFY_USER = 'NOTIFY_USER'
export const DELETE_LABEL = 'DELETE_LABEL'
export const LABEL_MANUAL = 'LABEL_MANUAL'

//FORM TEMPLATES
export const CREATE_TEMPLATE = 'CREATE_TEMPLATE'
export const FETCH_TEMPLATES = 'FETCH_TEMPLATES'
export const FETCH_TEMPLATE = 'FETCH_TEMPLATE'
export const EDIT_TEMPLATE = 'EDIT_TEMPLATE'
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE'

// TIER PRICE
export const FETCH_TIER_PRICES = 'FETCH_TIER_PRICES'
export const FETCH_TIER_PRICE = 'FETCH_TIER_PRICE'
export const CREATE_TIER_PRICE = 'CREATE_TIER_PRICE'
export const UPDATE_TIER_PRICE = 'UPDATE_TIER_PRICE'
export const DELETE_TIER_PRICE = 'DELETE_TIER_PRICE'
export const FETCH_DP_DATA = 'FETCH_DP_DATA'
export const FETCH_BATCH_DP_DATA = 'FETCH_BATCH_DP_DATA'

//ROLES
export const FETCH_ROLE = 'FETCH_ROLE'
export const CREATE_ROLE = 'CREATE_ROLE'
export const EDIT_ROLE = 'EDIT_ROLE'
export const DELETE_ROLE = 'DELETE_ROLE'

//PERMISSIONS
export const ADD_PERMISSIONS = 'ADD_PERMISSIONS'
export const DESTROY_PRODUCT_ARTWORK = 'DESTROY_PRODUCT_ARTWORK'
export const FETCH_TAG_SETS = 'FETCH_TAG_SETS'
export const CREATE_PORTAL_TAG = 'CREATE_PORTAL_TAG'
export const EDIT_PORTAL_TAG = 'EDIT_PORTAL_TAG'
export const FETCH_SHOW_TAG_SET = 'FETCH_SHOW_TAG_SET'
export const FETCH_PORTAL_TAG = 'FETCH_PORTAL_TAG'
export const UPDATE_DIGITAL_PROOFING_PRODUCT = 'UPDATE_DIGITAL_PROOFING_PRODUCT'

//WHITELISTED IPS
export const FETCH_WHITELISTED_IPS = 'FETCH_WHITELISTED_IPS'
export const CREATE_WHITELISTED_IP = 'CREATE_WHITELISTED_IP'
export const EDIT_WHITELISTED_IP = 'EDIT_WHITELISTED_IP'

//BLACKLISTED IPS
export const FETCH_BLACKLISTED_IPS = 'FETCH_BLACKLISTED_IPS'
export const CREATE_BLACKLISTED_IP = 'CREATE_BLACKLISTED_IP'
export const EDIT_BLACKLISTED_IP = 'EDIT_BLACKLISTED_IP'

// DROPSHIP BATCHES
export const CREATE_DROPSHIP_BATCH = 'CREATE_DROPSHIP_BATCH'
export const FETCH_DROPSHIP_BATCHES = 'FETCH_DROPSHIP_BATCHES'
export const FETCH_DROPSHIP_BATCH = 'FETCH_DROPSHIP_BATCH'

// DROPSHIP TEMPLATES
export const FETCH_DROPSHIP_TEMPLATES = 'FETCH_DROPSHIP_TEMPLATES'

// DROPSHIP IMAGES
export const CREATE_DROPSHIP_IMAGE = 'CREATE_DROPSHIP_IMAGE'
export const FETCH_DROPSHIP_IMAGES = 'FETCH_DROPSHIP_IMAGES'
export const DELETE_DROPSHIP_IMAGE = 'DELETE_DROPSHIP_IMAGE'
export const EDIT_DROPSHIP_IMAGE = 'EDIT_DROPSHIP_IMAGE'

// CHECKOUT FORMS
export const CREATE_CHECKOUT_FORM = 'CREATE_CHECKOUT_FORM'
export const FETCH_CHECKOUT_FORMS = 'FETCH_CHECKOUT_FORMS'
export const FETCH_CHECKOUT_FORM = 'FETCH_CHECKOUT_FORM'
export const EDIT_CHECKOUT_FORM = 'EDIT_CHECKOUT_FORM'

// UI BUTTONS
export const SAVE_AND_ACTION_CHANGE = 'SAVE_AND_ACTION_CHANGE'
export const SET_LOGIN_AS_CUSTOMER_OPTIONS = 'SET_LOGIN_AS_CUSTOMER_OPTIONS'

// USER DETAILS / CUSTOMER DETAILS
export const FETCH_CUSTOMER_PORTALS = 'FETCH_CUSTOMER_PORTALS'
export const FETCH_CUSTOMER_LOCATIONS = 'FETCH_CUSTOMER_LOCATIONS'
export const FETCH_CUSTOMER_CUSTOMER_GROUPS = 'FETCH_CUSTOMER_CUSTOMER_GROUPS'
export const SET_SELECTED_CUSTOMER_GROUP = 'SET_SELECTED_CUSTOMER_GROUP'
export const SET_CUSTOMER_ACTIVE = 'SET_CUSTOMER_ACTIVE'
export const TOGGLE_CUSTOMER_LOCATION = 'TOGGLE_CUSTOMER_LOCATION'
export const TOGGLE_CUSTOMER_PORTAL = 'TOGGLE_CUSTOMER_PORTAL'
export const REFRESH_LOGIN_BUTTON = 'REFRESH_LOGIN_BUTTON'
export const SET_ALL_LOCATIONS_ASSIGNED = 'SET_ALL_LOCATIONS_ASSIGNED'
export const CLEAR_USER_LOCATIONS = 'CLEAR_USER_LOCATIONS'
export const SET_CUSTOMER_GROUP_SYSTEM_MANAGED = 'SET_CUSTOMER_GROUP_SYSTEM_MANAGED'
export const REFRESH_PORTALS_LOCATIONS = 'REFRESH_PORTALS_LOCATIONS'
export const CCDP_LIST_REFRESH_COUNT = 'CCDP_LIST_REFRESH_COUNT'
